import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { MultiSelect } from "react-multi-select-component";

const RegisterFormModel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const isView = params.id === "view";
  const toast = useToast();
  const isEditMode = !!location?.state?.data;

  const [gameList, setGameList] = useState([]);
  const [games, setGames] = useState([]);
  const [percentages, setPercentages] = useState({});
  const [gamesError, setGamesError] = useState(false);

  const getGameList = async () => {
    await apiAdminConfig.get("games/get").then((response) => {
      if (response?.data?.results?.length > 0) {
        setGameList(
          response.data.results.map((list) => ({
            label: list?.name ?? '',
            value: list?.id
          })) ?? []
        );
        const newPercentages = response.data.results.map((list) => {
          const percentageData = location?.state?.data?.gameWisePercentage.find((data) => {
            return data?.gameId == list?.id
          })
          if (!!percentageData) {
            return {
              percentage: percentageData.percentage,
              gameId: percentageData?.gameId
            }
          } else {
            return {
              percentage: '',
              gameId: list?.id
            }
          }

        }) ?? []
        setPercentages(newPercentages);
        setFieldValue("percentages", newPercentages);
        if (location?.state?.data?.games?.length > 0) {
          setGames(location?.state?.data?.games)
        }
      }
    }).catch((error) => {
      console.log("gameListError", error);
    });
  };

  useEffect(() => {
    getGameList();
  }, []);

  const [initialValues, setInitialValues] = useState({
    userName: '',
    email: '',
    password: '',
    phone: '',
    reportPassword: '',
    transactionsPassword: '',
    games: [],
    percentages: gameList.map(game => ({ gameId: game.value, percentage: '' })),
  });

  useEffect(() => {
    if (isEditMode) {
      setInitialValues({
        ...location?.state?.data,
        reportPassword: '',
        transactionsPassword: '',
        verifyPassword: ''
      }
      );
    }
  }, [location, isEditMode]);

  const validationSchema = yup.object().shape({
    userName: yup.string().min(3).max(50).required("User Name is a required field"),
    password: isEditMode ? yup.string().nullable() : yup.string().min(4).max(50).required("Password is a required field"),
    reportPassword: isEditMode
      ? yup.string().nullable()
      : yup.string().min(4).max(50).required("Report Password is a required field"),
    transactionsPassword: isEditMode
      ? yup.string().nullable()
      : yup.string().min(4).max(50).required("Transactions Password is a required field"),
    percentages: yup.array().of(
      yup.object().shape({
        percentage: yup.number().required("Percentage is required").min(0, "Percentage cannot be negative").max(2.5, "Percentage cannot exceed 2.5")
      })
    ).required("All percentages must be filled")
    // games: yup.array().min(1, 'At least one game must be selected').required(),
  });

  const { handleSubmit, handleChange, values, errors, touched ,setFieldValue} = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {

      if (games.length === 0) {
        setGamesError(true);
        return;
      }
      delete values.percentages

      const newPercentage = []
      percentages.forEach((item) => {
        if (!!item.percentage) {
          newPercentage.push(item)
        }
      })

      setGamesError(false);
      values['phone'] = values.phone.toString();
      values['gameWisePercentage'] = Object.values(newPercentage);
      values['games'] = games;

      if (isEditMode) {
        await apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, { ...values })
          .then((response) => {
            if (response?.status === 200) {
              navigate('/WinLucky/admin');
              toast({ message: 'Admin updated successfully', bg: 'success' });
            } else {
              toast({ message: response?.data?.message, bg: 'danger' });
            }
          }).catch((error) => {
            const { response } = error;
            toast({ message: response?.data?.message, bg: "danger" });
          });
      } else {
        await apiAdminConfig.post('auth/admin/admin', { ...values })
          .then((response) => {
            if (response?.status === 201) {
              navigate('/WinLucky/admin');
              toast({ message: 'Admin registered successfully', bg: 'success' });
            } else {
              toast({ message: response?.data?.message, bg: 'danger' });
            }
          }).catch((error) => {
            const { response } = error;
            toast({ message: response?.data?.message, bg: "danger" });
          });
      }
    },
  });

  const handleGameSelection = (selectedGames) => {
    setGames(selectedGames.map(game => game.value));
    setGamesError(false);
  };

  const handlePercentageChange = (gameId, value) => {

    const newPercentages = percentages.map(item =>
      item.gameId === gameId ? { ...item, percentage: value } : item
    );
    
    setPercentages(newPercentages);
    setFieldValue("percentages", newPercentages);

  };

  return (
    <main id="events">
      <Container className="admin_padding">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="table-header-container">
        <div className=" breadcumb">
              <h1 >{!isView ? (isEditMode ? 'EDIT ADMIN' : 'CREATE ADMIN') : 'View ADMIN'}</h1>
              <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / {!isView ? (isEditMode ? 'EDIT ADMIN' : 'CREATE ADMIN') : 'View ADMIN'}</p>
            </div>
          <Link to="/WinLucky/admin" className="page_add_btn">Back</Link>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">ENTER DETAILS</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>User Name<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        placeholder="Enter User Name"
                        value={values.userName}
                        onChange={handleChange}
                        disabled={isView}
                        isInvalid={!!errors.userName && touched.userName}
                      />
                      <Form.Control.Feedback type="invalid">{errors.userName}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik02">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        value={values.email}
                        disabled={isView}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik03">
                      <Form.Label>Phone No</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        placeholder="Enter Phone No"
                        value={values.phone}
                        disabled={isView}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {!isView && (
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="validationFormik04">
                        <Form.Label>Password{!isEditMode && <span className="text-danger">*</span>}</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password && touched.password}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik05">
                      <Form.Label>Transactions Password<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="password"
                        name="transactionsPassword"
                        placeholder="Enter Transactions Password"
                        value={values.transactionsPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.transactionsPassword && touched.transactionsPassword}
                      />
                      <Form.Control.Feedback type="invalid">{errors.transactionsPassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik06">
                      <Form.Label>Report Password{!isEditMode && <span className="text-danger">*</span>}</Form.Label>
                      <Form.Control
                        type="password"
                        name="reportPassword"
                        placeholder="Enter Report Password"
                        value={values.reportPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.reportPassword && touched.reportPassword}
                      />
                      <Form.Control.Feedback type="invalid">{errors.reportPassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {gameList.length > 0 && (
                    <>
                      <Form.Label>SET PERCENTAGE</Form.Label>
                      <Row style={{ height: '150px', overflow: 'auto', border: '1px solid #ccc', padding: '10px', marginLeft: "7px" }} className="mb-3">
                        {gameList.map((game, index) => (
                          <Col md={6} className="mb-3" key={game.value}>
                            <Form.Group controlId={`validationFormikPercentage${game.value}`}>
                              <Form.Label className="label-color">{game?.label?.toUpperCase()}</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Available.Per-->2.5"
                                value={percentages[index]["percentage"] || ''}
                                onChange={(e) => handlePercentageChange(game.value, e.target.value)}
                                isInvalid={touched.percentages && errors.percentages && errors.percentages[index]}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.percentages && errors.percentages[index]?.percentage}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        ))}
                      </Row>
                    </>


                  )}
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik07">
                      <Form.Label>ELIGIBLE GAME<span className="text-danger">*</span></Form.Label>
                      <MultiSelect
                        options={gameList}
                        value={games.map((val) => gameList.find((list) => list.value === val))}
                        onChange={handleGameSelection}
                        labelledBy="Select Game"
                      />
                      {gamesError && <p className="text-danger">At least one game is required</p>}

                    </Form.Group>
                  </Col>
                </Row>

                <div >
                  {!isView && (
                    <Button type="submit" className="input_file_button">
                      {isEditMode ? 'EDIT ADMIN' : 'CREATE ADMIN'}
                    </Button>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default RegisterFormModel;
