import React from "react";
import { Container, Spinner } from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate ,useLocation} from "react-router-dom";
import { Space, Table, Popconfirm, Button, Image, Input } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import moment from "moment";


const BussinessReportDetail = () => {
    const [bussinessReportDeatilList, setBussinessReportDeatilList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

   
    const location = useLocation();
    const data = location
    const bussinessReportRole = location.pathname.split('/')[1]
    const toast = useToast();

    const navigate = useNavigate();

   
    React.useEffect(() => {
       
        
        const mergedData = location.state.games.reduce((acc, curr) => {
            const existing = acc.find(item => item._id === curr._id);
            if (existing) {
                existing.adminCommission += curr.adminCommission || 0;
                existing.agentCommission += curr.agentCommission || 0;
                existing.distributorCommission += curr.distributorCommission || 0;
                existing.partnerCommission += curr.partnerCommission || 0;
                existing.subDistributorCommission += curr.subDistributorCommission || 0;
                existing.playPoints += curr.playPoints || 0;
                existing.winPoints += curr.winPoints || 0;
            } else {
                acc.push({ ...curr });
            }
            return acc;
        }, []);
        
        setBussinessReportDeatilList(mergedData)

        
    }, [])

 
    const columns = [
       
        {
            title: 'Game Name',
            dataIndex: 'gameName',
            key: 'Game Name',
        },
        {
            title: 'Total Bet',
            dataIndex: 'playPoints',
            key: 'Total Bet',
            render: text => text || '0',
        },
        {
            title: 'Total Won',
            dataIndex: 'winPoints',
            key: 'Total Won',
            render: text => text || '0',
        },
        {
            title: 'End Point',
            dataIndex: 'endPoints',
            key: 'End Point',
            render: (_, record) => {
                const endPoint = (record?.playPoints || 0) - (record?.winPoints || 0);
                return <>{endPoint}</>;
            }

        },
        ...(bussinessReportRole == 'partner' ? [{
            title: 'Margin',
            dataIndex: 'distributorCommission',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.playPoints || 0) - (record?.winPoints || 0)) - (record?.distributorCommission || 0);
                return <>{endPoint}</>;
            }
        },
        {
            title: 'Partner Profit',
            dataIndex: 'partnerCommission',
            key: 'Partner Profit',
            render: text => text || '0',
        }
        ]
            : []),
        ...(bussinessReportRole == 'distributor' ? [{
            title: 'Margin',
            dataIndex: 'subDistributorCommission',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.playPoints || 0) - (record?.winPoints || 0)) - (record?.subDistributorCommission || 0);
                return <>{endPoint}</>;
            }
        },
        {
            title: 'Distributor Profit',
            dataIndex: 'distributorCommission',
            key: 'Distributor Profit',
            render: text => text || '0',
        }
        ] :
            []
        ),
        ...(bussinessReportRole == 'sub-distributor' ? [{
            title: 'Margin',
            dataIndex: 'agentCommission',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.playPoints || 0) - (record?.winPoints || 0)) - (record?.agentCommission || 0);
                return <>{endPoint}</>;
            }
        },
        {
            title: 'Sub Distributor Profit',
            dataIndex: 'subDistributorCommission',
            key: 'Sub Distributor Profit',
            render: text => text || '0',
        }
        ] : []),
        ...(bussinessReportRole == 'agent' ? [
            {
                title: 'Agent Profit',
                dataIndex: 'agentCommission',
                key: 'Agent Profit',
                render: text => text || '0',
            }
        ] : []
        ),
        ...(bussinessReportRole == 'user' ? [
            {
                title: 'User Profit',
                dataIndex: 'winPoints',
                key: 'User Profit',
                render: text => text || '0',
            }
        ] : []),
        
    ]




    return (
        <main id="bussinessReport">
            <Container className="admin_padding">
            <div className="container breadcumb">
            <h1>{bussinessReportRole?.toUpperCase()} BUSSINESS REPORT DETAIL</h1>
            <p className="fw-bold "><span style={{ color: 'blue' ,cursor:'pointer'}} onClick={()=>{ navigate( "/")}}>Home </span> / {bussinessReportRole?.toUpperCase()} BUSSINESS REPORT DETAIL</p>
                </div>
                <div className="admin_padding_table">
                    
                    <div className="table-container">
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        {
                            loading ?
                                <Table dataSource={bussinessReportDeatilList} scroll={{ x: 525 }} />
                                :
                                <Table bordered dataSource={bussinessReportDeatilList} columns={columns} scroll={{ x: 525 }}
                                    pagination={false}

                                     />

                        }
                    </div>
                </div>
            </Container>
        </main>
    );
};

export default BussinessReportDetail;

