import "./style.scss";
import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";


const GameResult = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const gameDetail = location.state.data
  const isRoundtwo = location.state?.data?.playgame?.cardDrawn?.some((data) => {
    return data?.round == 1
  })
  console.log("gameDetail", gameDetail)
  return (
    <div id="app" className="app app-header-fixed app-sidebar-fixed app-footer-fixed">

      <div style={{ marginLeft: '10px', marginBottom: '30px', marginRight: '10px' }}>
        <Row >
          <Col className="mb-3" md={6}>
            <Form.Group controlId="validationFormik01">
              <Form.Label>
                NAME
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                disabled
                value={gameDetail.userName}
              />
            </Form.Group>
          </Col>


          <Col md={6} className="mb-3">
            <Form.Group controlId="validationFormik08">
              <Form.Label>GAME TYPE</Form.Label>
              <Form.Control
                type="text"
                name="gamename"
                disabled
                value={gameDetail.gameName}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div style={{ marginLeft: '10px', marginBottom: '30px', marginRight: '10px' }}>
        <Row>
          <Col className="mb-3" md={6}>
            <Form.Group controlId="validationFormik01">
              <Form.Label>
                TIME OF BET
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={moment(gameDetail?.playgame?.createdAt).format('DD-MM-YYYY ss:mm ')}
                disabled
              />
            </Form.Group>
          </Col>


          <Col md={6} className="mb-3">
            <Form.Group controlId="validationFormik08">
              <Form.Label>HAND ID</Form.Label>
              <Form.Control
                type="text"
                name="handId"
                disabled
                value={gameDetail.playgame.handId}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>


      <div className="row">
        <div className="col-12">
          <div className=" m-b-15 game-card">
            {
              gameDetail?.gameId == "670f59a91f4b5e974cc6f053" ?
                <>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className=" m-b-6 ">
                        <div className="bg-primary">
                          <div className="card-header card-header-inverse" style={{ alignItems: "center" }}>
                            <h4 className="card-header-title" style={{ margin: '0' }}> BET AND WINNING DETAILS</h4>
                          </div>
                        </div>
                        <div className=" m-b-12">
                          <div className="card-body" style={{ overflow: 'auto' }}>
                            <table className="table table-bordered m-b-0">
                              <thead>
                                <tr style={{ color: '#f70202', textAlign: "center" }} >
                                  <th></th>
                                  <th colspan="2"><b>ANDAR BET<b></b></b></th>
                                  <th colspan="2"><b>BAHAR BET<b></b></b></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <thead>
                                <tr style={{ color: '#f24e02', textAlign: "center" }} >
                                  <th>USERNAME</th>
                                  <th>ROUND 1</th>
                                  <th>ROUND 2</th>
                                  <th>ROUND 1</th>
                                  <th>ROUND 2</th>
                                  <th>WINNER</th>
                                  <th>TOTAL BET</th>
                                  <th>TOTAL WON POINTS</th>
                                  <th>CONNECTIVITY DEVICE</th>
                                  <th>BONUS</th>
                                </tr>
                              </thead>
                              <tbody style={{ color: '#007500', textAlign: "center" }} >
                                <tr><td><b>{gameDetail.userName}</b></td>
                                  <td>20</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>
                                    {gameDetail.betSide}
                                  </td>
                                  <td>{gameDetail?.playPoints}</td>
                                  <td>0</td>
                                  <td>mobile</td>
                                  <td>
                                    0
                                  </td>
                                </tr></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <div className="card-header">
                        <div className="card-header" style={{ backgroundColor: '#3275a8', color: '#ffffff' }} >
                          BAHAR CARD
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className={`table-responsive ${isRoundtwo ? 'col-md-6' : ''}`}>
                              <table className="table table-bordered" id="list">
                                <thead>
                                  <tr>
                                    <th style={{ color: '#f24e02', textAlign: 'center' }} >ROUND 1</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {
                                        gameDetail?.playgame?.cardDrawn?.map((data) => {
                                          return <>
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'A') && <img src="/asset/diamond_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'K') && <img src="/asset/diamond_13.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '2') && <img src="/asset/diamond_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '3') && <img src="/asset/diamond_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '4') && <img src="/asset/diamond_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '5') && <img src="/asset/diamond_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '6') && <img src="/asset/diamond_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '7') && <img src="/asset/diamond_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '8') && <img src="/asset/diamond_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '9') && <img src="/asset/diamond_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '10') && <img src="/asset/diamond_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'J') && <img src="/asset/diamond_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'Q') && <img src="/asset/diamond_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'A') && <img src="/asset/club_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '2') && <img src="/asset/club_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '3') && <img src="/asset/club_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '4') && <img src="/asset/club_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '5') && <img src="/asset/club_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '6') && <img src="/asset/club_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '7') && <img src="/asset/club_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '8') && <img src="/asset/club_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '9') && <img src="/asset/club_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '10') && <img src="/asset/club_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'J') && <img src="/asset/club_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'Q') && <img src="/asset/club_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'K') && <img src="/asset/club_13.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'K') && <img src="/asset/heart_13.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'A') && <img src="/asset/heart_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '2') && <img src="/asset/heart_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '3') && <img src="/asset/heart_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '4') && <img src="/asset/heart_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '5') && <img src="/asset/heart_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '6') && <img src="/asset/heart_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '7') && <img src="/asset/heart_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '8') && <img src="/asset/heart_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '9') && <img src="/asset/heart_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '10') && <img src="/asset/heart_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'J') && <img src="/asset/heart_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'Q') && <img src="/asset/heart_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'A') && <img src="/asset/spade_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '2') && <img src="/asset/spade_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '3') && <img src="/asset/spade_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '4') && <img src="/asset/spade_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '5') && <img src="/asset/spade_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '6') && <img src="/asset/spade_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '7') && <img src="/asset/spade_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '8') && <img src="/asset/spade_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '9') && <img src="/asset/spade_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == '10') && <img src="/asset/spade_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'J') && <img src="/asset/spade_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'Q') && <img src="/asset/spade_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'K') && <img src="/asset/spade_13.png" height="52px" width="48px" />}
                                          </>
                                        })
                                      }

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {
                              isRoundtwo &&
                              <div className="table-responsive  col-md-6">
                                <table className="table table-bordered" id="list">
                                  <thead>
                                    <tr>
                                      <th style={{ color: '#f24e02', textAlign: 'center' }} >ROUND 2</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {
                                          gameDetail?.playgame?.cardDrawn?.map((data) => {
                                            return <>
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'A') && <img src="/asset/diamond_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'K') && <img src="/asset/diamond_13.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '2') && <img src="/asset/diamond_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '3') && <img src="/asset/diamond_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '4') && <img src="/asset/diamond_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '5') && <img src="/asset/diamond_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '6') && <img src="/asset/diamond_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '7') && <img src="/asset/diamond_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '8') && <img src="/asset/diamond_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '9') && <img src="/asset/diamond_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == '10') && <img src="/asset/diamond_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'J') && <img src="/asset/diamond_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Diamonds" && data.value == 'Q') && <img src="/asset/diamond_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'A') && <img src="/asset/club_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '2') && <img src="/asset/club_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '3') && <img src="/asset/club_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '4') && <img src="/asset/club_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '5') && <img src="/asset/club_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '6') && <img src="/asset/club_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '7') && <img src="/asset/club_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '8') && <img src="/asset/club_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '9') && <img src="/asset/club_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == '10') && <img src="/asset/club_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'J') && <img src="/asset/club_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'Q') && <img src="/asset/club_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Clubs" && data.value == 'K') && <img src="/asset/club_13.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'K') && <img src="/asset/heart_13.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'A') && <img src="/asset/heart_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '2') && <img src="/asset/heart_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '3') && <img src="/asset/heart_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '4') && <img src="/asset/heart_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '5') && <img src="/asset/heart_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '6') && <img src="/asset/heart_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '7') && <img src="/asset/heart_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '8') && <img src="/asset/heart_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '9') && <img src="/asset/heart_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == '10') && <img src="/asset/heart_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'J') && <img src="/asset/heart_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Hearts" && data.value == 'Q') && <img src="/asset/heart_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'A') && <img src="/asset/spade_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '2') && <img src="/asset/spade_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '3') && <img src="/asset/spade_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '4') && <img src="/asset/spade_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '5') && <img src="/asset/spade_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '6') && <img src="/asset/spade_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '7') && <img src="/asset/spade_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '8') && <img src="/asset/spade_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '9') && <img src="/asset/spade_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == '10') && <img src="/asset/spade_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'J') && <img src="/asset/spade_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'Q') && <img src="/asset/spade_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Bahar" && data.suit == "Spades" && data.value == 'K') && <img src="/asset/spade_13.png" height="52px" width="48px" />}
                                            </>
                                          })
                                        }

                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2">
                      <div className="">
                        <div className="card-header">
                          <div className="card-header" style={{ backgroundColor: '#eb2842', color: '#ffffff' }} >
                            JOKER CARD
                          </div>
                        </div>
                        <div className="card-body">
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == 'A') && <img src="/asset/diamond_1.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == 'K') && <img src="/asset/diamond_13.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '2') && <img src="/asset/diamond_2.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '3') && <img src="/asset/diamond_3.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '4') && <img src="/asset/diamond_4.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '5') && <img src="/asset/diamond_5.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '6') && <img src="/asset/diamond_6.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '7') && <img src="/asset/diamond_7.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '8') && <img src="/asset/diamond_8.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '9') && <img src="/asset/diamond_9.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == '10') && <img src="/asset/diamond_10.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == 'J') && <img src="/asset/diamond_11.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Diamonds" && gameDetail?.playgame?.gameCard?.value == 'Q') && <img src="/asset/diamond_12.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == 'A') && <img src="/asset/club_1.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '2') && <img src="/asset/club_2.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '3') && <img src="/asset/club_3.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '4') && <img src="/asset/club_4.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '5') && <img src="/asset/club_5.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '6') && <img src="/asset/club_6.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '7') && <img src="/asset/club_7.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '8') && <img src="/asset/club_8.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '9') && <img src="/asset/club_9.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == '10') && <img src="/asset/club_10.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == 'J') && <img src="/asset/club_11.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == 'Q') && <img src="/asset/club_12.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Clubs" && gameDetail?.playgame?.gameCard?.value == 'K') && <img src="/asset/club_13.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == 'K') && <img src="/asset/heart_13.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == 'A') && <img src="/asset/heart_1.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '2') && <img src="/asset/heart_2.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '3') && <img src="/asset/heart_3.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '4') && <img src="/asset/heart_4.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '5') && <img src="/asset/heart_5.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '6') && <img src="/asset/heart_6.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '7') && <img src="/asset/heart_7.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '8') && <img src="/asset/heart_8.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '9') && <img src="/asset/heart_9.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == '10') && <img src="/asset/heart_10.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == 'J') && <img src="/asset/heart_11.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Hearts" && gameDetail?.playgame?.gameCard?.value == 'Q') && <img src="/asset/heart_12.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == 'A') && <img src="/asset/spade_1.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '2') && <img src="/asset/spade_2.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '3') && <img src="/asset/spade_3.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '4') && <img src="/asset/spade_4.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '5') && <img src="/asset/spade_5.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '6') && <img src="/asset/spade_6.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '7') && <img src="/asset/spade_7.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '8') && <img src="/asset/spade_8.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '9') && <img src="/asset/spade_9.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == '10') && <img src="/asset/spade_10.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == 'J') && <img src="/asset/spade_11.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == 'Q') && <img src="/asset/spade_12.png" height="52px" width="48px" />}
                          {(gameDetail?.playgame?.gameCard?.suit == "Spades" && gameDetail?.playgame?.gameCard?.value == 'K') && <img src="/asset/spade_13.png" height="52px" width="48px" />}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <div className="card-header">
                        <div className="card-header" style={{ backgroundColor: '#3275a8', color: '#ffffff' }} >
                          ANDAR CARD
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className={`table-responsive ${isRoundtwo ? 'col-md-6' : ''}`}>
                              <table className="table table-bordered" id="list">
                                <thead>
                                  <tr>
                                    <th style={{ color: '#f24e02', textAlign: 'center' }} >ROUND 1</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {
                                        gameDetail?.playgame?.cardDrawn?.map((data) => {
                                          return <>
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'A') && <img src="/asset/diamond_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'K') && <img src="/asset/diamond_13.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '2') && <img src="/asset/diamond_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '3') && <img src="/asset/diamond_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '4') && <img src="/asset/diamond_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '5') && <img src="/asset/diamond_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '6') && <img src="/asset/diamond_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '7') && <img src="/asset/diamond_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '8') && <img src="/asset/diamond_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '9') && <img src="/asset/diamond_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '10') && <img src="/asset/diamond_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'J') && <img src="/asset/diamond_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'Q') && <img src="/asset/diamond_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'A') && <img src="/asset/club_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '2') && <img src="/asset/club_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '3') && <img src="/asset/club_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '4') && <img src="/asset/club_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '5') && <img src="/asset/club_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '6') && <img src="/asset/club_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '7') && <img src="/asset/club_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '8') && <img src="/asset/club_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '9') && <img src="/asset/club_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == '10') && <img src="/asset/club_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'J') && <img src="/asset/club_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'Q') && <img src="/asset/club_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'K') && <img src="/asset/club_13.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'K') && <img src="/asset/heart_13.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'A') && <img src="/asset/heart_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '2') && <img src="/asset/heart_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '3') && <img src="/asset/heart_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '4') && <img src="/asset/heart_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '5') && <img src="/asset/heart_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '6') && <img src="/asset/heart_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '7') && <img src="/asset/heart_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '8') && <img src="/asset/heart_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '9') && <img src="/asset/heart_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == '10') && <img src="/asset/heart_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'J') && <img src="/asset/heart_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'Q') && <img src="/asset/heart_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == 'A') && <img src="/asset/spade_1.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '2') && <img src="/asset/spade_2.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '3') && <img src="/asset/spade_3.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '4') && <img src="/asset/spade_4.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '5') && <img src="/asset/spade_5.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '6') && <img src="/asset/spade_6.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '7') && <img src="/asset/spade_7.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '8') && <img src="/asset/spade_8.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '9') && <img src="/asset/spade_9.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == '10') && <img src="/asset/spade_10.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == 'J') && <img src="/asset/spade_11.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == 'Q') && <img src="/asset/spade_12.png" height="52px" width="48px" />}
                                            {(data.round == 1 && data.side == "Andar" && data.suit == "Spades" && data.value == 'K') && <img src="/asset/spade_13.png" height="52px" width="48px" />}
                                          </>
                                        })
                                      }

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {
                              isRoundtwo &&
                              <div className="table-responsive  col-md-6">
                                <table className="table table-bordered" id="list">
                                  <thead>
                                    <tr>
                                      <th style={{ color: '#f24e02', textAlign: 'center' }} >ROUND 2</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {
                                          gameDetail?.playgame?.cardDrawn?.map((data) => {
                                            return <>
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'A') && <img src="/asset/diamond_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'K') && <img src="/asset/diamond_13.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '2') && <img src="/asset/diamond_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '3') && <img src="/asset/diamond_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '4') && <img src="/asset/diamond_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '5') && <img src="/asset/diamond_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '6') && <img src="/asset/diamond_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '7') && <img src="/asset/diamond_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '8') && <img src="/asset/diamond_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '9') && <img src="/asset/diamond_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == '10') && <img src="/asset/diamond_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'J') && <img src="/asset/diamond_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Diamonds" && data.value == 'Q') && <img src="/asset/diamond_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'A') && <img src="/asset/club_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '2') && <img src="/asset/club_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '3') && <img src="/asset/club_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '4') && <img src="/asset/club_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '5') && <img src="/asset/club_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '6') && <img src="/asset/club_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '7') && <img src="/asset/club_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '8') && <img src="/asset/club_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '9') && <img src="/asset/club_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == '10') && <img src="/asset/club_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'J') && <img src="/asset/club_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'Q') && <img src="/asset/club_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Clubs" && data.value == 'K') && <img src="/asset/club_13.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'K') && <img src="/asset/heart_13.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'A') && <img src="/asset/heart_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '2') && <img src="/asset/heart_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '3') && <img src="/asset/heart_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '4') && <img src="/asset/heart_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '5') && <img src="/asset/heart_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '6') && <img src="/asset/heart_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '7') && <img src="/asset/heart_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '8') && <img src="/asset/heart_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '9') && <img src="/asset/heart_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == '10') && <img src="/asset/heart_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'J') && <img src="/asset/heart_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Hearts" && data.value == 'Q') && <img src="/asset/heart_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == 'A') && <img src="/asset/spade_1.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '2') && <img src="/asset/spade_2.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '3') && <img src="/asset/spade_3.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '4') && <img src="/asset/spade_4.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '5') && <img src="/asset/spade_5.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '6') && <img src="/asset/spade_6.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '7') && <img src="/asset/spade_7.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '8') && <img src="/asset/spade_8.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '9') && <img src="/asset/spade_9.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == '10') && <img src="/asset/spade_10.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == 'J') && <img src="/asset/spade_11.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == 'Q') && <img src="/asset/spade_12.png" height="52px" width="48px" />}
                                              {(data.round == 2 && data.side == "Andar" && data.suit == "Spades" && data.value == 'K') && <img src="/asset/spade_13.png" height="52px" width="48px" />}
                                            </>
                                          })
                                        }

                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  <div style={{ overflow: 'auto', minWidth: '500px', margin: '10px' }}>
                    <table className="table table-bordered m-b-0">
                      <thead>
                        <tr style={{ color: '#f24e02' }}>
                          <th>WINNING NO</th>
                          <th>WINNER MONEY</th>
                          <th>TOTAL BET</th>
                          <th>CONNECTIVITY DEVICE</th>
                          <th>BONUS</th>
                        </tr>
                      </thead>
                      <tbody style={{ color: '#007500' }}>
                        <tr>
                          <td>{gameDetail?.playgame?.winNumber}</td>
                          <td>350</td>
                          <td>{gameDetail?.playPoints}</td>
                          <td>mobile</td>
                          <td>0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div id="Roulette_Wrap">
                    {/* <div style={{ width: '25px' }} className="spot0"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET" >2</a></div> */}
                    {(gameDetail?.predictions?.includes(0) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot0"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET" >{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(1) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot1"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET" >{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(2) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot2"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(3) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot3"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(4) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot4"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(5) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot5"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(6) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot6"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(7) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot7"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(8) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot8"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(9) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot9"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(10) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot10"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(11) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot11"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(12) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot12"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(13) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot13"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(14) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot14"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(15) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot15"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(16) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot16"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(17) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot17"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(18) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot18"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(19) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot19"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(20) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot20"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(21) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot21"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(22) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot22"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(23) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot23"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(24) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot24"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(25) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot25"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(26) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot26"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(27) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot27"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(28) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot28"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(29) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot29"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(30) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot30"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(31) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot31"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(32) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot32"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(33) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot33"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(34) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot34"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(35) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot35"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions?.includes(36) && gameDetail.betType == "number") && <div style={{ width: '25px' }} className="spot36"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>
                    }
                    {gameDetail.betType == "1stColumn" && <div style={{ width: '25px' }} className="c1"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "2ndColumn" && <div style={{ width: '25px' }} className="c2"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "3rdColumn" && <div style={{ width: '25px' }} className="c3"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "red" && <div style={{ width: '25px' }} className="red"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "black" && <div style={{ width: '25px' }} className="black"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "odd" && <div style={{ width: '25px' }} className="odd"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "even" && <div style={{ width: '25px' }} className="even"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "1st12" && <div style={{ width: '25px' }} className="first-twelve"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "2nd12" && <div style={{ width: '25px' }} className="second-twelve"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "3rd12" && <div style={{ width: '25px' }} className="third-twelve"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "1to18" && <div style={{ width: '25px' }} className="one-eighteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "19to36" && <div style={{ width: '25px' }} className="twenteen-thirtysix"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "Tier" && <div style={{ width: '25px' }} className="tiers"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "Orphelins" && <div style={{ width: '25px' }} className="orphelines"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "Voisins" && <div style={{ width: '25px' }} className="voisins"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {gameDetail.betType == "ZeroSpiel" && <div style={{ width: '25px' }} className="zero-spile"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail?.predictions.sort().toString() === [1, 2].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-one-two"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [1, 4].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-one-four"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [2, 3].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-two-three"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [2, 5].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-two-five"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [3, 6].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thrre-six"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [6, 9].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-six-nine"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [4, 5].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-five-four"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [5, 6].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-six-five"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [5, 8].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-five-eight"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [4, 7].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-four-seven"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {/* {( gameDetail?.predictions.sort().toString() === [7,8].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-seven-eight"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>} */}
                    {(gameDetail?.predictions.sort().toString() === [7, 10].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-seven-ten"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [7, 8].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-seven-eight"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [8, 9].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-eight-nine"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [8, 11].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-eight-eleven"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [9, 12].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-nine-twelev"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [12, 15].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twelev-fifteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [12, 11].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twelev-eleven"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [11, 14].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-eleven-fouteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [10, 11].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-eleven-ten"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [10, 13].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-ten-thirteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [13, 14].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirteen-fourteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [13, 16].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirteen-sixteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [16, 17].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-sixteen-seventeen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [16, 19].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-sixteen-nineteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [15, 18].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-fifteenteen-eightteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [23, 24].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentythree-twentyfour"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail?.predictions.sort().toString() === [17, 18].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-seventeen-eightteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [17, 20].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-seventeen-twenty"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail?.predictions.sort().toString() === [14, 15].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-fourtteen-fifteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [14, 17].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-fourtteen-seventeen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [18, 21].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-eightteen-twentyone"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [20, 21].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyone-twenty"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [21, 24].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyone-twentyfour"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [20, 23].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twenty-twentythree"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail?.predictions.sort().toString() === [20, 19].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twenty-nineteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [19, 21].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-nineteen-twentytwo"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [21, 23].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentytwo-twentythree"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [22, 25].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentytwo-twentyfive"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [23, 26].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentythree-twentysix"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [24, 27].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyfour-twentyseven"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [26, 27].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyseven-twentysix"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [27, 30].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyseven-thirty"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [25, 26].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentysix-twentyfive"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail?.predictions.sort().toString() === [26, 29].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentysix-twentynine"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [25, 28].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyfive-twentyeight"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [28, 29].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyeight-twentynine"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [28, 31].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyeight-thirtyone"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [29, 30].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentynine-thirty"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [29, 32].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentynine-thirtytwo"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [30, 33].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirty-thirtythree"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [33, 36].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtythree-thirtysix"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [32, 33].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtythree-thirtytwo"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [31, 32].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtytwo-thirtyone"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [32, 35].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtytwo-thirtyfive"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [31, 34].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtyone-thirtyfour"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [35, 36].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtyfive-thirtysix"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [34, 35].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtyfour-thirtyfive"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail?.predictions.sort().toString() === [1, 2, 5, 4].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-one-to-four"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [2, 3, 6, 5].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-two-to-five"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [4, 5, 8, 7].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-four-to-seven"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [5, 6, 9, 8].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-five-to-eight"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [7, 8, 11, 10].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-seven-to-ten"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [8, 9, 12, 11].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-eight-to-eleven"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [10, 11, 14, 13].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-ten-to-thirteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [11, 12, 15, 14].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-eleven-to-fourteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [13, 14, 17, 16].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirteen-to-sixteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [14, 15, 18, 17].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-fourteen-to-seventeen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [16, 17, 20, 19].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-sixteen-to-nineteen"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [17, 18, 21, 20].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-seventeen-to-twenty"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [19, 20, 23, 22].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-nineteen-to-twentytwo"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [20, 21, 24, 23].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twenty-to-twentythree"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [22, 23, 26, 25].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentytwo-to-twentyfive"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [23, 24, 27, 26].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentythree-to-twentysix"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [26, 27, 30, 29].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentysix-to-twentynine"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail?.predictions.sort().toString() === [25, 26, 29, 28].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyfive-to-twentyeight"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [28, 29, 32, 31].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentyeight-to-thirtyone"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [29, 30, 33, 32].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-twentynine-to-thirtytwo"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [31, 32, 35, 34].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtyone-to-thirtyfour"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}
                    {(gameDetail?.predictions.sort().toString() === [32, 33, 36, 35].sort().toString() && gameDetail.betType == "Split") && <div style={{ width: '25px' }} className="split-thirtytwo-to-thirtyfive"><a className="aroulet" href="javascript:void(0);" title="NORMAL BET">{gameDetail?.playPoints}</a></div>}

                    {(gameDetail.playgame.winNumber == 0) && <div className="spotw0">win</div>}
                    {(gameDetail.playgame.winNumber == 1) && <div className="spotw1">win</div>}
                    {(gameDetail.playgame.winNumber == 2 ) && <div className="spotw2">win</div>}
                    {(gameDetail.playgame.winNumber == 3 ) && <div className="spotw3">win</div>}
                    {(gameDetail.playgame.winNumber == 4 ) && <div className="spotw4">win</div>}
                    {(gameDetail.playgame.winNumber == 5) && <div className="spotw5">win</div>}
                    {(gameDetail.playgame.winNumber == 6) && <div className="spotw6">win</div>}
                    {(gameDetail.playgame.winNumber == 7 ) && <div className="spotw7">win</div>}
                    {(gameDetail.playgame.winNumber == 8 ) && <div className="spotw8">win</div>}
                    {(gameDetail.playgame.winNumber == 9 ) && <div className="spotw9">win</div>}
                    {(gameDetail.playgame.winNumber == 10 ) && <div className="spotw10">win</div>}
                    {(gameDetail.playgame.winNumber == 11 ) && <div className="spotw11">win</div>}
                    {(gameDetail.playgame.winNumber == 12 ) && <div className="spotw12">win</div>}
                    {(gameDetail.playgame.winNumber == 13) && <div className="spotw13">win</div>}
                    {(gameDetail.playgame.winNumber == 14 ) && <div className="spotw14">win</div>}
                    {(gameDetail.playgame.winNumber == 15 ) && <div className="spotw15">win</div>}
                    {(gameDetail.playgame.winNumber == 16) && <div className="spotw16">win</div>}
                    {(gameDetail.playgame.winNumber == 17) && <div className="spotw17">win</div>}
                    {(gameDetail.playgame.winNumber == 18 ) && <div className="spotw18">win</div>}
                    {(gameDetail.playgame.winNumber == 19 ) && <div className="spotw19">win</div>}
                    {(gameDetail.playgame.winNumber == 20 ) && <div className="spotw20">win</div>}
                    {(gameDetail.playgame.winNumber == 21) && <div className="spotw21">win</div>}
                    {(gameDetail.playgame.winNumber == 22 ) && <div className="spotw22">win</div>}
                    {(gameDetail.playgame.winNumber == 23) && <div className="spotw23">win</div>}
                    {(gameDetail.playgame.winNumber == 24 ) && <div className="spotw24">win</div>}
                    {(gameDetail.playgame.winNumber == 25 ) && <div className="spotw25">win</div>}
                    {(gameDetail.playgame.winNumber == 26) && <div className="spotw26">win</div>}
                    {(gameDetail.playgame.winNumber == 27 ) && <div className="spotw27">win</div>}
                    {(gameDetail.playgame.winNumber == 28 ) && <div className="spotw28">win</div>}
                    {(gameDetail.playgame.winNumber == 29) && <div className="spotw29">win</div>}
                    {(gameDetail.playgame.winNumber == 30 ) && <div className="spotw30">win</div>}
                    {(gameDetail.playgame.winNumber == 31) && <div className="spotw31">win</div>}
                    {(gameDetail.playgame.winNumber == 32) && <div className="spotw32">win</div>}
                    {(gameDetail.playgame.winNumber == 33 ) && <div className="spotw33">win</div>}
                    {(gameDetail.playgame.winNumber == 34 ) && <div className="spotw34">win</div>}
                    {(gameDetail.playgame.winNumber == 35) && <div className="spotw35">win</div>}
                    {(gameDetail.playgame.winNumber == 36 ) && <div className="spotw36">win</div>}
                  </div>

                  <div id="Roulette_Neighbour_Wrap"></div>
                </>
            }




          </div>
        </div>
      </div>

      {/* <div style={{ marginTop: '20px' }} className="row">

        <div className="col-lg-6">
          <div className="form-group row m-b-10">
            <div className="offset-sm-8 col-sm-10" style={{ textAlign: 'center', marginBottom: '10px' }}>
             
            </div>
          </div>
        </div>
      </div> */}
      <div style={{textAlign:'center',marginTop: '15px'}}>
        <Button className="input_file_button" onClick={() => navigate('/WinLucky/game-transaction')}>
          CLOSE
        </Button>
      </div>
    </div>
  );
};

export default GameResult;

