import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { apiAdminConfig } from "../../utils/api";
import { getRole } from "../../utils/cookie";
import { Table, Input ,Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";


const PointFile = () => {
    const [pointFileList, setPointFileList] = React.useState([]);
    const [userRole, setUserRole] = React.useState(getRole());
    const [totalCashIn, setTotalCashIn] = useState(0)
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    const [search, setSearch] = React.useState("");

    // const [partnerList, setPartnerList] = useState([])
    const [disributorList, setDistributorList] = useState([])
    const [subDisributorList, setSubDistributorList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [userList, setUserList] = useState([])
  

    const { Text } = Typography;

    const navigate = useNavigate();


    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const initialValues = {
        startDate: '',
        endDate: '',
        pickDate: '',
        ...((userRole == 'admin' || userRole == 'partner') && { distributor: '', subDistributor: '', agent: '', user: '' }),
        ...((userRole == 'distributor') && { subDistributor: '', agent: '', user: '' }),
        ...((userRole == 'subDistributor' || userRole === 'superAdmin') && { agent: '', user: '' }),
        ...((userRole === 'agent') && { user: '' }),
    };

    useEffect(() => {
        // if (userRole == 'admin') {
        //     getPartner();
        // }
        if (userRole == 'admin' || userRole == 'partner') {
            getDistributor();
        }
        if (userRole == 'distributor') {
            getSubDistributor();
        }
        if (userRole == 'subDistributor') {
            getAgent();
        }
        if (userRole == 'agent') {
            getUser();
        }
    }, [])


    const getUser = async (id = '') => {
        await apiAdminConfig.get('users/get?role=user').then((response) => {
            if (response?.data?.results?.length > 0) {
                setUserList(response?.data?.results)
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };


    const getSubDistributor = async (id = '') => {
        await apiAdminConfig.get(`users/list?userBy=subDistributor&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getAgent = async (id = '') => {
        await apiAdminConfig.get(`users/list?userBy=agent&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setAgentList(response?.data)
            } else {
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getDistributor = async (id = '') => {
        // const parentId = (id == "" && !!values.admin) ? values.admin : id

        await apiAdminConfig.get(`users/list?userBy=distributor&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setDistributorList(response?.data)
            } else {
                setDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };
    // const getPartner = async (id = '') => {
    //     setFieldValue("distributor", '')


    //     await apiAdminConfig.get(`users/list?userBy=partner`).then((response) => {
    //         console.log("+-----", response)
    //         if (response?.data?.length > 0) {
    //             setPartnerList(response?.data)
    //         } else {
    //             setPartnerList([])
    //         }
    //     }).catch((error) => {
    //         console.log("certificateError", error);
    //     })

    // }



    const getPointFileList = async (pickDate = '', toDate = '', fromDate = '',id='') => {
        setLoading(true);
        try {
            const url =id ? `points/points-file?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&userId=${id}` : `points/points-file?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}`
            const response = await apiAdminConfig.get(
               url
                // `points/transactions?type=OUT&pickDate=`

            );
           
           
            if (response?.status === 200) {
                setPointFileList(response?.data?.result);
               
                // const totalPoint = response?.data?.result?.reduce((total = 0, item) => {
                //     return total + item.points;
                // }, 0);

                // setTotalCashIn(totalPoint);
            }
        } catch (error) {
            console.log("ListError", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getPointFileList(); // Initial load
    }, [search]);

    const columns = [
        {
            title: '#',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {(currentPage - 1) * pageSize + index + 1}
                    </>
                )
            }
        },
        {
            title: 'Before Transaction',
            dataIndex: 'beforePoints',
            key: 'Before Transaction',
        },
        {
            title: 'IN',
            dataIndex: 'inPoint',
            key: 'IN',
            width: 150,
            render: text => text || '-',
        },
        {
            title: 'OUT',
            dataIndex: 'outPoint',
            key: 'OUT',
            className: 'out-column',
            render: text => text || '-',
        },
        {
            title: 'After Transaction',
            dataIndex: 'afterPoints',
            key: 'After Transaction',
            render: text => text || '-',
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'Transaction ID',
            render: text => text || '-',
        },
        {
            title: 'Receiver Name',
            dataIndex: 'receiverName',
            key: 'Receiver Name',
            render: (_, record) => record?.receiver?.userName || "N/A",
        },
        {
            title: 'Receiver Type',
            dataIndex: 'receiverType',
            key: 'Receiver Type',
            render: (_, record) => record?.receiver?.role || "N/A",
        },
        {
            title: 'Sender Name',
            dataIndex: 'senderName',
            key: 'Sender Name',
            render: (_, record) => record?.sender?.userName || "N/A",
        },
        {
            title: 'Sender Type',
            dataIndex: 'senderType',
            key: 'Sender Type',
            render: (_, record) => record?.sender?.role || "N/A",
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'Date',
            render: text => text ? moment(text).format('DD-MM-YYYY') : '-',
        }
    ];
    const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async () => {
            const { pickDate, startDate, endDate ,user} = values;
            await getPointFileList(pickDate, endDate, startDate,user);
        },
    });
    return (
        <main>

            <Container className="admin_padding">
                <div style={{ display: "flex", justifyContent: "space-between", margin: "14px 0", alignItems: "center", padding: "0 10px" }} className="table-hader-container">
                    <div className=" breadcumb">
                        <h1 > DETAIL POINT TRANSACTION</h1>
                        <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> /  DETAIL POINT TRANSACTION</p>
                    </div>

                </div>
                <div className="admin_form" style={{ width: '98%', marginBottom: '20px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#f33120' }}>DATE TIME FILTER</h6>
                    </div>
                    <Row className="admin_add_form">

                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    {/* {
                                        (userRole === "admin") &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Partner
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="partner"
                                                    value={values.partner}
                                                    onChange={(e) => {
                                                        setFieldValue("partner", e.target.value)
                                                        getDistributor(e.target.value)
                                                        setFieldValue("distributor", '')
                                                        setDistributorList([])

                                                    }}
                                                >
                                                    <option value=''>Select Partner</option>
                                                    {partnerList && partnerList.length > 0 && partnerList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    } */}
                                    {
                                        (userRole == 'admin' || userRole == 'partner') && (
                                            // <Row>
                                            <Col className="mb-3" md={6}>
                                                <Form.Group controlId="validationFormik01">
                                                    <Form.Label>
                                                        Select Distributor
                                                    </Form.Label>
                                                    <Form.Select
                                                        type="text"
                                                        name="distributor"
                                                        value={values.distributor}
                                                        onChange={(e) => {
                                                            setFieldValue("distributor", e.target.value)
                                                            if (e.target.value) {
                                                                getSubDistributor(e.target.value)
                                                            } else {
                                                                setSubDistributorList([])
                                                                setAgentList([])
                                                                setFieldValue("subDistributor", '')
                                                                setFieldValue("agent", '')
                                                            }
                                                        }}
                                                    >
                                                        <option value=''>Select Distributor</option>
                                                        {disributorList && disributorList.length > 0 && disributorList.map((item, index) => {
                                                            return (
                                                                <option value={item?.id} >{item?.userName}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            // </Row>
                                        )
                                    }
                                    {
                                        (userRole == 'admin' || userRole == 'partner' || userRole == 'distributor') &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Sub Distributor
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="subDistributor"
                                                    value={values.subDistributor}
                                                    disabled={(userRole === "admin" || userRole === "partner") && !values.distributor}
                                                    onChange={(e) => {
                                                        setFieldValue("subDistributor", e.target.value)
                                                        if (e.target.value) {
                                                            getAgent(e.target.value)
                                                        } else {
                                                            setAgentList([])
                                                            setFieldValue("agent", '')
                                                        }
                                                    }}
                                                >
                                                    <option value="">Select Sub Distributor</option>
                                                    {subDisributorList && subDisributorList.length > 0 && subDisributorList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    

                                    {
                                        (userRole == 'admin' || userRole == 'partner' || userRole == 'distributor' || userRole == 'subDistributor') &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Agent
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="agent"
                                                    value={values.agent}
                                                    disabled={(userRole === "admin" || userRole === "partner" || userRole === "distributor") && !values.subDistributor}
                                                    onChange={(e) => {
                                                        setFieldValue("agent", e.target.value)
                                                        if (e.target.value) {
                                                            getUser(e.target.value)
                                                        } else {
                                                            setUserList([])
                                                            setFieldValue("user", '')
                                                        }
                                                    }}
                                                >
                                                    <option value=''>Select Agent</option>
                                                    {agentList && agentList.length > 0 && agentList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                    {
                                        (userRole == 'admin' || userRole == 'partner' || userRole == 'distributor' || userRole == 'subDistributor' || userRole == 'agent') &&
                                        <Col md={6} className="mb-3">
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>User</Form.Label>
                                                <Form.Select
                                                    as="select"
                                                    name="user"
                                                    value={values.user}
                                                    disabled={(userRole === "admin" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && !values.agent}
                                                    onChange={(e) => setFieldValue("user", e.target.value)}
                                                >
                                                    <option value="">Select User</option>
                                                    {userList.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.userName}
                                                        </option>
                                                    ))}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                                
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>START DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="startDate"
                                                value={values.startDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik02">
                                            <Form.Label>END DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="endDate"
                                                value={values.endDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6} >
                                        <Form.Group controlId="validationFormik03">
                                            <Form.Label>PICK DATE</Form.Label>
                                            <Form.Select
                                                name="pickDate"
                                                value={values.pickDate}
                                                onChange={handleChange}
                                            >
                                                <option value=''>SELECTED DATE</option>
                                                <option value='today'>TODAY</option>
                                                <option value='yesterday'>YESTERDAY</option>
                                                <option value='thisWeek'>THIS WEEK</option>
                                                <option value='lastWeek'>LAST WEEK</option>
                                                <option value='thisMonth'>THIS MONTH</option>
                                                <option value='lastMonth'>LAST MONTH</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "center" }} className="footer-btns">
                                    <Button type="submit" className="input_file_button">
                                        SUBMIT
                                    </Button>
                                </div>
                            </Form>
                        </Col>

                    </Row>
                </div>

                <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: 'rgb(56 39 221)', padding: "1rem" }}></h6>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div className="page-size">
                            <span className="fw-bold">Show</span>
                            <select onChange={(e) => setPageSize(e.target.value)}
                                value={pageSize} className="selecte-page-size">
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                            <span className="fw-bold">entries</span>
                        </div>

                        <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                }}
                            />
                        </div>

                    </div>
                    <div className="table-container" style={{ margin: "20px" }}>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        <Table  bordered dataSource={pointFileList} columns={columns} scroll={{ x: 525 }}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                total: pointFileList?.length,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                },
                                showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                            }}

                            onChange={handleTableChange} 
                            summary={(pageData) => {
                                let totalBorrow = 0;
                                let totalRepayment = 0;
                                pageData.forEach(({ inPoint, outPoint }) => {
                                  totalBorrow += inPoint;
                                  totalRepayment += outPoint;
                                });
                                return (
                                  <>
                                    <Table.Summary.Row style={{ backgroundColor: 'rgb(251 251 251)', fontWeight: 'bold' }}>
                                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                      <Table.Summary.Cell index={2} >
                                        <Text>{totalBorrow}</Text>
                                      </Table.Summary.Cell>
                                      <Table.Summary.Cell index={4}>
                                        <Text>{totalRepayment}</Text>
                                      </Table.Summary.Cell>
                                      <Table.Summary.Cell index={5}>
                                        <Text></Text>
                                      </Table.Summary.Cell> <Table.Summary.Cell index={6}>
                                        <Text></Text>
                                      </Table.Summary.Cell> <Table.Summary.Cell index={7}>
                                        <Text></Text>
                                      </Table.Summary.Cell> <Table.Summary.Cell index={8}>
                                        <Text></Text>
                                      </Table.Summary.Cell> <Table.Summary.Cell index={9}>
                                        <Text></Text>
                                      </Table.Summary.Cell> 
                                      <Table.Summary.Cell index={10}>
                                        <Text></Text>
                                      </Table.Summary.Cell>
                                      <Table.Summary.Cell index={11}>
                                        <Text></Text>
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                  
                                  </>
                                );
                            }}
/>
                       
                </div>
            </div>
        </Container>
        </main >
    );
};

export default PointFile;

