// import "../style.scss";
// import React, { useState, useEffect } from "react";
// import { useLocation, Link } from "react-router-dom";
// import { apiAdminConfig } from "../../../utils/api";
// import moment from "moment";
// import { convertArrayToString } from "../../../utils/helper";
// import { Form } from "react-bootstrap";

// const UserViewModel = () => {
//     const location = useLocation();
//     const [userDetail, setUserDetail] = useState(null)
//     const [lockStatus, setLockStatus] = useState(false)

//     const getUserDetail = async () => {
//         await apiAdminConfig.get(`users/get/${location?.state?.data?.id}`).then((response) => {
//             if (response?.status == 200) {
//                 setUserDetail(response?.data)
//                 setLockStatus(response?.data?.lockStatus == 'locked' ? true : false)
//             }
//         }).catch((error) => {
//             console.log("error", error);
//         })
//     }

//     useEffect(() => {
//         getUserDetail()
//     }, [location]);

//     const handleLockStatus = (e) => {
//         let status = e.target.checked ? 'locked' : 'unlocked';
//         setLockStatus(e.target.checked)
//         apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, { lockStatus: status }).then((response) => {
//             if (response?.status == 200) {
//                 getUserDetail()

//             }
//         }).catch((error) => {
//             console.log("error", error);
//         })
//     }

//     return <>
//         <main id="userView">

//             <div
//                 style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     margin: "14px 0",
//                     alignItems: "center",
//                 }}
//                 className="table-hader-container"
//             >
//                 <Link to="/WinLucky/user" className="page_add_btn" style={{ marginLeft: "20px" }}>
//                     Back
//                 </Link>
//             </div>

//             <div className='d-flex mt-3' >
//                 <div className='d-flex mt-4 container' style={{ marginLeft: "10px" }}>
//                     <div className='user-card text-white bg-primary mb-3'>
//                         <div className="card-body">
//                             <h5 className="card-title">TOTAL POINTS</h5>
//                             <p className="card-text">{userDetail?.points ?? 0}</p>
//                         </div>
//                     </div>
//                     <div className='user-card text-white mb-3' style={{ background: "#8515ebe3" }}>
//                         <div className="card-body">
//                             <h5 className="card-title">TOTAL BETS</h5>
//                             <p className="card-text">{userDetail?.totalPlayPoints ?? 0}</p>
//                         </div>
//                     </div>
//                     <div className='user-card text-white mb-3' style={{ background: "#f94a4a" }}>
//                         <div className="card-body">
//                             <h5 className="card-title">TOTAL WON</h5>
//                             <p className="card-text">{userDetail?.totalWinPoints ?? 0}</p>
//                         </div>
//                     </div>
//                     <div className='user-card text-white bg-warning mb-3'>
//                         <div className="card-body">
//                             <h5 className="card-title">TOTAL CONSUMED</h5>
//                             <p className="card-text">{(userDetail?.totalPlayPoints || 0) - (userDetail?.totalWinPoints || 0)}</p>
//                         </div>
//                     </div>

//                 </div>
//                 <div className='card text-black  mb-3'>
//                     <div className="card-body">
//                         <h5 className="card-title">User Detail</h5>
//                         <hr></hr>
//                         <p>
//                             <span className="label">User Name</span>
//                             <span className="colon">:</span>
//                             <span className="value">{userDetail?.userName || '-'}</span>
//                         </p>
//                         <p>
//                             <span className="label">Point</span>
//                             <span className="colon">:</span>
//                             <span className="value">{userDetail?.points || '0'}</span>
//                         </p>
//                         <p>
//                             <span className="label">Email</span>
//                             <span className="colon">:</span>
//                             <span className="value">{userDetail?.email || '-'}</span>
//                         </p>
//                         <p>
//                             <span className="label">Phone No</span>
//                             <span className="colon">:</span>
//                             <span className="value">{userDetail?.phone || '-'}</span>
//                         </p>
//                         {
//                             userDetail?.admin &&
//                             <p>
//                                 <span className="label">Admin</span>
//                                 <span className="colon">:</span>
//                                 <span className="value">{userDetail?.admin?.userName || '-'}</span>
//                             </p>
//                         }
//                         {
//                             userDetail?.partner &&
//                             <p>
//                                 <span className="label">Partner</span>
//                                 <span className="colon">:</span>
//                                 <span className="value">{userDetail?.partner?.userName || '-'}</span>
//                             </p>
//                         }
//                         {
//                             userDetail?.distributor &&
//                             <p>
//                                 <span className="label">Distributor</span>
//                                 <span className="colon">:</span>
//                                 <span className="value">{userDetail?.distributor?.userName || '-'}</span>
//                             </p>
//                         }
//                         {
//                             userDetail?.subDistributor &&
//                             <p>
//                                 <span className="label">Sub Distributor</span>
//                                 <span className="colon">:</span>
//                                 <span className="value">{userDetail?.subDistributor?.userName || '-'}</span>
//                             </p>
//                         }
//                         {
//                             userDetail?.agent &&
//                             <p>
//                                 <span className="label">Agent</span>
//                                 <span className="colon">:</span>
//                                 <span className="value">{userDetail?.agent?.userName || '-'}</span>
//                             </p>
//                         }
//                         <p>
//                             <span className="label">Last Login</span>
//                             <span className="colon">:</span>
//                             <span className="value">{moment(userDetail?.lastLogin).format('DD-MM-YYYY') || '-'}</span>
//                         </p>
//                         <p>
//                             <span className="label">Locked Status</span>
//                             <span className="colon">:</span>
//                             <span className="value">{userDetail?.lockStatus || '-'}</span>
//                         </p>
//                         <p>
//                             <span className="label">Locked By</span>
//                             <span className="colon">:</span>
//                             <span className="value">{userDetail?.lockedBy?.userName || 'NULL'}</span>
//                         </p>
//                         <p>
//                             <span className="label">Eligible Game</span>
//                             <span className="colon">:</span>
//                             <span className="value">{
//                                 userDetail?.games?.length > 0 ? convertArrayToString(userDetail?.games?.map((item) => item.name)) : '-'
//                             }</span>
//                         </p>
//                         <p>
//                             <span className="label">Lock</span>
//                             <span className="colon">:</span>
//                             <label className="switch">
//                                 <Form.Check
//                                     type="switch"
//                                     id="custom-switch"
//                                     className="custom-switch"
//                                     checked={lockStatus}
//                                     onChange={(e) => handleLockStatus(e)}
//                                 />
//                             </label>
//                         </p>
//                     </div>
//                 </div>
//             </div>

//         </main >
//     </>
// }

// export default UserViewModel





import "../style.scss";
import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { apiAdminConfig } from "../../../utils/api";
import moment from "moment";
import { convertArrayToString } from "../../../utils/helper";
import { Form, Modal, Button, Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup"; // for form validation
import { MultiSelect } from "react-multi-select-component";
import useToast from "../../../provider/Toast/useToast";

const UserViewModel = () => {
    const location = useLocation();
    const toast = useToast();

    const [userDetail, setUserDetail] = useState(null);
    const [lockStatus, setLockStatus] = useState(false);
    const [games, setGames] = useState([]);
    const [gameList, setGameList] = useState([]);


    // States to manage the visibility of modals
    const [showLoginPasswordModal, setShowLoginPasswordModal] = useState(false);
    // const [showTransactionPasswordModal, setShowTransactionPasswordModal] = useState(false);
    // const [showReportPasswordModal, setShowReportPasswordModal] = useState(false);
    // const [showCommissionPercentagedModal, setShowCommissionPercentagedModal] = useState(false);
    const navigate = useNavigate();


    const getUserDetail = async () => {
        await apiAdminConfig
            .get(`users/get/${location?.state?.data?.id}`)
            .then((response) => {
                if (response?.status === 200) {
                    setUserDetail(response?.data);
                    console.log("response", response.data?.games)

                    setGames(response?.data?.games?.map((list) => list.id) ?? []);
                    setGameList(
                        response?.data?.games.map((list) => ({
                            label: list?.name ?? '',
                            value: list?.id
                        })) ?? []
                    );
                    setLockStatus(response?.data?.lockStatus === "locked" ? true : false);
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getUserDetail();
    }, [location]);

    const handleLockStatus = (e) => {
        let status = e.target.checked ? "locked" : "unlocked";
        setLockStatus(e.target.checked);
        apiAdminConfig
            .put(`users/update/${location?.state?.data?.id}`, { lockStatus: status })
            .then((response) => {
                if (response?.status === 200) {
                    getUserDetail();
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    // Validation Schema for each password type
    const passwordValidationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("New password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            .required("Confirm password is required"),
    });

    const handlePasswordReset = async (values, { setSubmitting, setFieldError }, passwordType) => {
        try {
            let obj = {}
            if (passwordType === "login") {
                obj = {
                    password: values.newPassword,
                }
            } else if (passwordType === "transaction") {
                obj = {
                    transactionsPassword: values.newPassword,
                }
            } else if (passwordType === "report") {
                obj = {
                    reportPassword: values.newPassword,
                }
            }
            const response = await apiAdminConfig.put(
                `users/update/${userDetail?._id}`,
                obj
            );

            if (response?.status === 200) {
                // Close the respective modal on successful password reset
                if (passwordType === "login") {
                    setShowLoginPasswordModal(false);
                }
                // else if (passwordType === "transaction") {
                //     setShowTransactionPasswordModal(false);
                // } else if (passwordType === "report") {
                //     setShowReportPasswordModal(false);
                // }
                toast({
                    message: 'Password Upadate successfully',
                    bg: 'success'
                });
            } else {
                toast({
                    message: response?.data?.message,
                    bg: "danger",
                });
            }
        } catch (error) {
            console.error(`${passwordType} password reset failed:`, error);
            setFieldError("newPassword", `Failed to reset ${passwordType} password. Please try again.`);
        } finally {
            setSubmitting(false);
        }
    };

    // const changePercentage = (e, index) => {
    //     const newPercentage = [...userDetail?.gameWisePercentage];
    //     newPercentage[index].percentage = e.target.value;
    //     setUserDetail((prevState) => ({
    //         ...prevState,
    //         gameWisePercentage: newPercentage
    //     }));
    // };

    // const handlePercentageChange = async (index) => {
    //     const response = await apiAdminConfig.put(
    //         `users/change-percentage`,
    //         {
    //             "percentage": userDetail?.gameWisePercentage[index]?.percentage,
    //             "gameId": userDetail?.gameWisePercentage[index]?._id,
    //             "userId": userDetail?._id
    //         }
    //     );

    //     if (response?.status === 200) {
    //         setShowCommissionPercentagedModal(false);
    //         toast({
    //             message: 'Percentage Update successfully',
    //             bg: 'success'
    //         });
    //     } else {
    //         toast({
    //             message: response?.data?.message,
    //             bg: "danger",
    //         });
    //     }
    // }

    const handleGameSelection = (selectedGames) => {
        console.log("selectedGames", selectedGames)
        setGames(selectedGames.map(game => game?.value));
    };

    const changeAligibleGame = async () => {
        const response = await apiAdminConfig.put(
            `users/update/${userDetail?._id}`,
            { games: games }
        );
        if (response?.status === 200) {
            toast({
                message: 'Change Aligible Game successfully',
                bg: 'success'
            });
            getUserDetail()

        } else {
            toast({
                message: response?.data?.message,
                bg: "danger",
            });
        }
    }
    return (
        <>
            <main id="userView">
                <Container className="admin_padding">

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className="table-hader-container"
                    >
                        <div className=" breadcumb">
                            <h1>{userDetail?.userName}-PROFILE</h1>
                            <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / Profile</p>
                        </div>
                    </div>
                    {/* <div className="admin_form"> */}
                    <div className='d-flex mt-4 user-card-back' style={{ padding: '0', marginLeft: '0', marginRight: '0', backgroundColor: '#fbfbfb' }}>
                        <div className='user-card text-white first-card mb-3'>
                            <div className="card-body">
                                <h5 className="card-title">TOTAL POINTS</h5>
                                <p className="card-text">{userDetail?.points ?? 0}</p>
                            </div>
                        </div>
                        <div className='user-card text-white mb-3 forth-card' >
                            <div className="card-body">
                                <h5 className="card-title">TOTAL BETS</h5>
                                <p className="card-text">{userDetail?.totalPlayPoints ?? 0}</p>
                            </div>
                        </div>
                        <div className='user-card text-white mb-3 second-card' >
                            <div className="card-body">
                                <h5 className="card-title">TOTAL WON</h5>
                                <p className="card-text">{userDetail?.totalWinPoints ?? 0}</p>
                            </div>
                        </div>
                        <div className='user-card text-white third-card mb-3'>
                            <div className="card-body">
                                <h5 className="card-title">TOTAL CONSUMED</h5>
                                <p className="card-text">{(userDetail?.totalPlayPoints || 0) - (userDetail?.totalWinPoints || 0)}</p>
                            </div>
                        </div>

                    </div>
                    {/* </div> */}

                    <div className="admin_form">

                        <div className="detail-heading">
                            <h6 className="text-white" style={{ backgroundColor: 'rgb(255 91 85)' }}>EDIT ELIGIBLE GAME</h6>
                        </div>
                        <Row className="admin_add_form">
                            <Col>
                                {/* <Form noValidate onSubmit={handleSubmit}> */}
                                <Row className="mb-3" style={{ justifyContent: 'center', marginTop: '10px' }}>
                                    <Col md={6} className="mb-3">
                                        <Form.Group >
                                            <Form.Label>ELIGIBLE GAME</Form.Label>
                                            <MultiSelect
                                                options={gameList}
                                                value={games.map((val) => gameList?.find((list) => list?.value === val))}
                                                onChange={handleGameSelection}
                                                labelledBy="Select Game"
                                            />

                                        </Form.Group>
                                    </Col>

                                </Row>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >

                                    <Button type="submit" className="input_file_button" onClick={() => { changeAligibleGame() }}>
                                        CHANGE ALIGIBLE GAME
                                    </Button>

                                </div>
                                {/* </Form> */}
                            </Col>
                        </Row>
                    </div>
                    <div className="admin_form" style={{ marginTop: '20px' }}>
                        <div className="detail-heading">
                            <h6 className="text-white" style={{ backgroundColor: 'rgb(38 133 227)', padding: "1rem" }}>FULL DETAIL</h6>
                        </div>
                        <div>
                            <div className=" text-black mb-3 full-width-card">
                                <div className="card-body ">
                                    {/* <h5 className="card-title">Partner Detail</h5>
                <hr /> */}

                                    {/* Table for partner details */}

                                    <table className="table table-bordered detail-heading" >
                                        <tbody>
                                            <tr>
                                                <td className="label">User Name</td>
                                                <td className="values">{userDetail?.userName || "-"}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">Point</td>
                                                <td>{userDetail?.points || "0"}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">TOTAL BETS</td>
                                                <td>{userDetail?.totalPlayPoints || "0"}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">TOTAL WON</td>
                                                <td>{userDetail?.totalWinPoints || "0"}</td>
                                            </tr>

                                            <tr>
                                                <td className="label">Email</td>
                                                <td>{userDetail?.email || "-"}</td>
                                            </tr>

                                            <tr>
                                                <td className="label">Phone No</td>
                                                <td>{userDetail?.phone || "-"}</td>
                                            </tr>
                                            {userDetail?.admin && (
                                                <tr>
                                                    <td className="label">Admin</td>
                                                    <td>{userDetail?.admin?.userName || "-"}</td>
                                                </tr>
                                            )}

                                            {userDetail?.partner && (
                                                <tr>
                                                    <td className="label">Partner</td>
                                                    <td>{userDetail?.partner?.userName || "-"}</td>
                                                </tr>
                                            )}

                                            {userDetail?.distributor && (
                                                <tr>
                                                    <td className="label">Distributor</td>
                                                    <td>{userDetail?.distributor?.userName || "-"}</td>
                                                </tr>
                                            )}

                                            {userDetail?.subDistributor && (
                                                <tr>
                                                    <td className="label">Sub Distributor</td>
                                                    <td>{userDetail?.subDistributor?.userName || "-"}</td>
                                                </tr>
                                            )}

                                            {userDetail?.agent && (
                                                <tr>
                                                    <td className="label">Agent</td>
                                                    <td>{userDetail?.agent?.userName || "-"}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className="label">Last Login</td>
                                                <td>{moment(userDetail?.lastLogin).format("DD-MM-YYYY") || "-"}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">Locked Status</td>
                                                <td>{userDetail?.lockStatus || "-"}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">Locked By</td>
                                                <td>{userDetail?.lockedBy?.userName || "NULL"}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">TOTAL USER IN HAND</td>
                                                <td>{userDetail?.totalUserInHand || "0"}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">Eligible Game</td>
                                                <td>
                                                    {userDetail?.games?.length > 0
                                                        ? convertArrayToString(userDetail?.games?.map((item) => item.name))
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="label">Lock</td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={lockStatus}
                                                        onChange={(e) => handleLockStatus(e)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="label">Login Password Reset</td>
                                                <td>
                                                    <Button variant="warning" onClick={() => setShowLoginPasswordModal(true)}>
                                                        RESET LOGIN PASSWORD
                                                    </Button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </Container>
            </main>

            <Modal show={showLoginPasswordModal} onHide={() => setShowLoginPasswordModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Login Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ newPassword: "", confirmPassword: "" }}
                        validationSchema={passwordValidationSchema}
                        onSubmit={(values, actions) => handlePasswordReset(values, actions, "login")}
                    >
                        {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="newPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="newPassword"
                                        value={values.newPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.newPassword && errors.newPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="confirmPassword" style={{ marginTop: '10px' }}>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.confirmPassword && errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                </Form.Group>

                                <Button type="submit" disabled={isSubmitting} style={{ marginTop: '10px' }}>RESET</Button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default UserViewModel;



















