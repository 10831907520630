import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { useFormik } from "formik";
import { apiAdminConfig } from "../../utils/api";
import { getRole } from "../../utils/cookie";
import { Table, Input, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';


const BussinessReport = () => {
    const [bussinessReportList, setBussinessReportList] = React.useState([]);
    const [userRole, setUserRole] = React.useState(getRole());
    const [totalCashIn, setTotalCashIn] = useState(0)
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    const [search, setSearch] = React.useState("");
    const [bussinessReportData, setBussinessReportData] = React.useState([]);

    const [partnerList, setPartnerList] = useState([])
    const [disributorList, setDistributorList] = useState([])
    const [subDisributorList, setSubDistributorList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [userList, setUserList] = useState([])
    const [isShowTable, setIsShowTable] = useState(false)


    const { Text } = Typography;

    const navigate = useNavigate();

    const location = useLocation();
    const bussinessReportRole = location.pathname.split('/')[2]

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const initialValues = {
        startDate: '',
        endDate: '',
        pickDate: '',
        device: '',
        reportPassword: '',
        ...((bussinessReportRole == 'partner') && { partner: '' }),
        ...((bussinessReportRole == 'distributor') && { distributor: '' }),
        ...((bussinessReportRole == 'sub-distributor') && { subDistributor: '' }),
        ...((bussinessReportRole == 'agent') && { agent: '' }),
        ...((bussinessReportRole == 'user') && { user: '' }),
    };

    useEffect(() => {
        if (bussinessReportRole == 'partner') {
            getPartner();
        }
        if (bussinessReportRole == 'distributor') {
            getDistributor();
        }
        if (bussinessReportRole == 'sub-distributor') {
            getSubDistributor();
        }
        if (bussinessReportRole == 'agent') {
            getAgent();
        }
        if (bussinessReportRole == 'user') {
            getUser();
        }
    }, [bussinessReportRole])


    const getUser = async (id = '') => {
        await apiAdminConfig.get('users/report/user?userBy=user').then((response) => {
            console.log("response?.data", response?.data)
            if (response?.data?.length > 0) {
                setUserList(response?.data)
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };


    const getSubDistributor = async (id = '') => {
        await apiAdminConfig.get(`users/report/user?userBy=subDistributor`).then((response) => {
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getAgent = async (id = '') => {
        await apiAdminConfig.get(`users/report/user?userBy=agent`).then((response) => {
            if (response?.data?.length > 0) {
                setAgentList(response?.data)
            } else {
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getDistributor = async (id = '') => {
        // const parentId = (id == "" && !!values.admin) ? values.admin : id

        await apiAdminConfig.get(`users/report/user?userBy=distributor`).then((response) => {
            if (response?.data?.length > 0) {
                setDistributorList(response?.data)
            } else {
                setDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };
    const getPartner = async (id = '') => {
        setFieldValue("distributor", '')


        await apiAdminConfig.get(`users/report/user?userBy=partner`).then((response) => {
            if (response?.data?.length > 0) {
                setPartnerList(response?.data)
            } else {
                setPartnerList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })

    }



    const getBussinessReportList = async (pickDate = '', toDate = '', fromDate = '', id = '', reportPassword = '', device = '', subDistributorId = '', distributorId = '', agentId = '', partner = '') => {
        setLoading(true);
        if (!!reportPassword) {
            setIsShowTable(true)
            try {
                let url = ''
                if (bussinessReportRole == 'partner') {
                    url = `users/business-report?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}&reportBy=partner&partnerId=${partner}`
                }
                if (bussinessReportRole == 'distributor') {
                    url = `users/business-report?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}&reportBy=distributor&distributorId=${distributorId}`
                }
                if (bussinessReportRole == 'sub-distributor') {
                    url = `users/business-report?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}&reportBy=subDistributor&subDistributorId=${subDistributorId}`
                }
                if (bussinessReportRole == 'agent') {
                    url = `users/business-report?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}&reportBy=agent&agentId=${agentId}`
                }
                if (bussinessReportRole == 'user') {
                    url = `users/business-report?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}&reportBy=user&userId=${id}`
                }
                // const url = `users/business-report?search=${search}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}`

                const response = await apiAdminConfig.get(
                    url
                    // `points/transactions?type=OUT&pickDate=`

                );


                if (response?.status === 200) {
                    let totalPlayPoint = 0;
                    let totalWonPoint = 0;
                    let totalEndPoint = 0;
                    let totalMergin = 0;
                    let totalNet = 0;
                    let totalProfit = 0
                    response?.data.forEach((data) => {
                        totalPlayPoint += data.totalPlayPoints;
                        totalWonPoint += data.totalWinPoints;
                        totalEndPoint += data.totalPlayPoints - data.totalWinPoints;
                        if (bussinessReportRole == 'partner') {
                            totalMergin += data.totalDistributorCommission
                            totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - (data?.totalDistributorCommission || 0);
                            totalProfit += data.totalPartnerCommission
                        }
                        if (bussinessReportRole == 'distributor') {
                            totalMergin += data.totalSubDistributorCommission
                            totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - (data?.totalSubDistributorCommission || 0);
                            totalProfit += data.totalDistributorCommission
                        }
                        if (bussinessReportRole == 'sub-distributor') {
                            totalMergin += data.totalAgentCommission
                            totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - (data?.totalAgentCommission || 0);
                            totalProfit += data.totalSubDistributorCommission
                        }
                        if (bussinessReportRole == 'agent') {
                            totalProfit += data.totalAgentCommission
                        }
                        if (bussinessReportRole == 'user') {
                            totalProfit += data.totalWinPoints
                        }
                    })
                    setBussinessReportList(response?.data);

                    const bussinessreportTotal = [{
                        totalPlayPoints: totalPlayPoint,
                        totalWinPoints: totalWonPoint,
                        endPoints: totalEndPoint,
                        totalMerginPoints: totalMergin,
                        totalNetPoints: totalNet,
                        totalProfitPoints: totalProfit,
                    }]

                    setBussinessReportData(bussinessreportTotal)

                    // const totalPoint = response?.data?.result?.reduce((total = 0, item) => {
                    //     return total + item.points;
                    // }, 0);

                    // setTotalCashIn(totalPoint);
                }
            } catch (error) {
                console.log("ListError", error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setIsShowTable(false)
        setBussinessReportList([])
        setBussinessReportData([])
    }, [bussinessReportRole])

    React.useEffect(() => {
        // setBussinessReportList([])
        // setBussinessReportData([])
        getBussinessReportList(); // Initial load
    }, [search, bussinessReportRole]);

    const column = [

        {
            title: `${bussinessReportRole?.charAt(0)?.toUpperCase() + bussinessReportRole?.slice(1).toLowerCase()}`,
            dataIndex: 'name',
            key: `${bussinessReportRole}`,
        },
        {
            title: 'Play Point',
            dataIndex: 'totalPlayPoints',
            key: 'Play Point',
            render: text => text || '0',
        },
        {
            title: 'Won Point',
            dataIndex: 'totalWinPoints',
            key: 'Won Point',
            render: text => text || '0',
        },
        {
            title: 'End Point',
            dataIndex: 'endPoints',
            key: 'End Point',
            render: text => text || '0',

        },
        ...(bussinessReportRole == 'partner' ? [{
            title: 'Margin',
            dataIndex: 'totalMerginPoints',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'totalNetPoints',
            key: 'Net',
            render: text => text || '0'
        },
        {
            title: 'Profit',
            dataIndex: 'totalProfitPoints',
            key: 'Admin Profit',
            render: text => text || '0',
        }
        ]
            : []),
        ...(bussinessReportRole == 'distributor' ? [{
            title: 'Margin',
            dataIndex: 'totalMerginPoints',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'totalNetPoints',
            key: 'Net',
            render: text => text || '0'
        },
        {
            title: 'Profit',
            dataIndex: 'totalProfitPoints',
            key: 'Admin Profit',
            render: text => text || '0',
        }
        ] :
            []
        ),
        ...(bussinessReportRole == 'sub-distributor' ? [{
            title: 'Margin',
            dataIndex: 'totalMerginPoints',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'totalNetPoints',
            key: 'Net',
            render: text => text || '0'
        },
        {
            title: 'Profit',
            dataIndex: 'totalProfitPoints',
            key: 'Admin Profit',
            render: text => text || '0',
        }
        ] : []),
        ...(bussinessReportRole == 'agent' ? [
            {
                title: 'Profit',
                dataIndex: 'totalProfitPoints',
                key: 'Admin Profit',
                render: text => text || '0',
            }
        ] : []
        ),
        ...(bussinessReportRole == 'user' ? [
            {
                title: 'Profit',
                dataIndex: 'totalProfitPoints',
                key: 'Admin Profit',
                render: text => text || '0',
            }
        ] : []),

        {
            title: 'Bonus',
            dataIndex: 'Bonus',
            key: 'Bonus',
            render: () => "0", // Use a render function to ensure consistency
        },

    ];

    const columns = [
        {
            title: '#',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {(currentPage - 1) * pageSize + index + 1}
                    </>
                )
            }
        },
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'User Name',
            render: (_, record) => (
                <button style={{ border: 'none', color: 'blue', background: 'none', fontWeight: 500 }} onClick={() => navigate(`/WinLucky/user-detail`, { state: record })}>
                    {record.name}
                </button>
            ),
        },
        {
            title: 'Play Point',
            dataIndex: 'totalPlayPoints',
            key: 'Play Point',
            render: text => text || '0',
        },
        {
            title: 'Won Point',
            dataIndex: 'totalWinPoints',
            key: 'Won Point',
            render: text => text || '0',
        },
        {
            title: 'End Point',
            dataIndex: 'endPoints',
            key: 'End Point',
            render: (_, record) => {
                const endPoint = (record?.totalPlayPoints || 0) - (record?.totalWinPoints || 0);
                return <>{endPoint}</>;
            }

        },
        ...(bussinessReportRole == 'partner' ? [{
            title: 'Margin',
            dataIndex: 'totalDistributorCommission',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.totalPlayPoints || 0) - (record?.totalWinPoints || 0)) - (record?.totalDistributorCommission || 0);
                return <>{endPoint}</>;
            }
        },
        {
            title: 'Partner Profit',
            dataIndex: 'totalPartnerCommission',
            key: 'Partner Profit',
            render: text => text || '0',
        }
        ]
            : []),
        ...(bussinessReportRole == 'distributor' ? [{
            title: 'Margin',
            dataIndex: 'totalSubDistributorCommission',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.totalPlayPoints || 0) - (record?.totalWinPoints || 0)) - (record?.totalSubDistributorCommission || 0);
                return <>{endPoint}</>;
            }
        },
        {
            title: 'Distributor Profit',
            dataIndex: 'totalDistributorCommission',
            key: 'Distributor Profit',
            render: text => text || '0',
        }
        ] :
            []
        ),
        ...(bussinessReportRole == 'sub-distributor' ? [{
            title: 'Margin',
            dataIndex: 'totalAgentCommission',
            key: 'Margin',
            render: text => text || '0',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.totalPlayPoints || 0) - (record?.totalWinPoints || 0)) - (record?.totalAgentCommission || 0);
                return <>{endPoint}</>;
            }
        },
        {
            title: 'Sub Distributor Profit',
            dataIndex: 'totalSubDistributorCommission',
            key: 'Sub Distributor Profit',
            render: text => text || '0',
        }
        ] : []),
        ...(bussinessReportRole == 'agent' ? [
            {
                title: 'Agent Profit',
                dataIndex: 'totalAgentCommission',
                key: 'Agent Profit',
                render: text => text || '0',
            }
        ] : []
        ),
        ...(bussinessReportRole == 'user' ? [
            {
                title: 'User Profit',
                dataIndex: 'totalWinPoints',
                key: 'User Profit',
                render: text => text || '0',
            }
        ] : []),
        {
            title: 'Bonus',
            dataIndex: 'Bonus',
            key: 'Bonus',
            render: () => "0",
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            key: 'Detail',
            render: (_, record) => (
                <button style={{ border: 'none', color: 'blue', background: 'none', fontWeight: 500 }} onClick={() => navigate(`/${bussinessReportRole}/bussiness-report-detail`, { state: record })}>
                    DETAILS
                </button>
            ),
        }

    ];
    const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async () => {
            const { pickDate, startDate, endDate, user, reportPassword, device, distributor, subDistributor, agent, partner } = values;
            await getBussinessReportList(pickDate, endDate, startDate, user, reportPassword, device, distributor, subDistributor, agent, partner);
        },
    });
    return (
        <main>

            <Container className="admin_padding">
                <div style={{ display: "flex", justifyContent: "space-between", margin: "14px 0", alignItems: "center", padding: "0 10px" }} className="table-hader-container">
                    <div className=" breadcumb">
                        <h1 >{bussinessReportRole?.toUpperCase()} DETAIL BUSINESS REPORT</h1>
                        <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / {bussinessReportRole?.toUpperCase()} DETAIL BUSINESS REPORT</p>
                    </div>

                </div>
                <div className="admin_form" style={{ width: '98%', marginBottom: '20px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#f33120' }}>FILTER</h6>
                    </div>
                    <Row className="admin_add_form">

                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    {
                                        (bussinessReportRole == 'partner') &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Partner
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="partner"
                                                    value={values.partner}
                                                    onChange={(e) => {
                                                        setFieldValue("partner", e.target.value)
                                                    }}
                                                >
                                                    <option value=''>Select Partner</option>
                                                    {partnerList && partnerList.length > 0 && partnerList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                    {
                                        (bussinessReportRole == 'distributor') && (
                                            // <Row>
                                            <Col className="mb-3" md={6}>
                                                <Form.Group controlId="validationFormik01">
                                                    <Form.Label>
                                                        Select Distributor
                                                    </Form.Label>
                                                    <Form.Select
                                                        type="text"
                                                        name="distributor"
                                                        value={values.distributor}
                                                        onChange={(e) => {
                                                            setFieldValue("distributor", e.target.value)
                                                        }}
                                                    >
                                                        <option value=''>Select Distributor</option>
                                                        {disributorList && disributorList.length > 0 && disributorList.map((item, index) => {
                                                            return (
                                                                <option value={item?.id} >{item?.userName}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            // </Row>
                                        )
                                    }
                                    {
                                        (bussinessReportRole == 'sub-distributor') &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Sub Distributor
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="subDistributor"
                                                    value={values.subDistributor}
                                                    onChange={(e) => {
                                                        setFieldValue("subDistributor", e.target.value)

                                                    }}
                                                >
                                                    <option value="">Select Sub Distributor</option>
                                                    {subDisributorList && subDisributorList.length > 0 && subDisributorList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                                <Row>


                                    {
                                        (bussinessReportRole == 'agent') &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Agent
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="agent"
                                                    value={values.agent}
                                                    onChange={(e) => {
                                                        setFieldValue("agent", e.target.value)

                                                    }}
                                                >
                                                    <option value=''>Select Agent</option>
                                                    {agentList && agentList.length > 0 && agentList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                    {
                                        (bussinessReportRole == 'user') &&
                                        <Col md={6} className="mb-3">
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>User</Form.Label>
                                                <Form.Select
                                                    as="select"
                                                    name="user"
                                                    value={values.user}
                                                    onChange={(e) => setFieldValue("user", e.target.value)}
                                                >
                                                    <option value="">Select User</option>
                                                    {userList.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.userName}
                                                        </option>
                                                    ))}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                                <Row>


                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik06">
                                            <Form.Label>Report Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="reportPassword"
                                                placeholder="Enter Report Password"
                                                value={values.reportPassword}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>START DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="startDate"
                                                value={values.startDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik02">
                                            <Form.Label>END DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="endDate"
                                                value={values.endDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>DEVICE</Form.Label>
                                        <div className="d-flex">
                                            <Form.Check
                                                type="radio"
                                                id="devicePC"
                                                name="device"
                                                label="PC"
                                                value="PC"
                                                checked={values.device === 'PC'}
                                                onChange={handleChange}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="deviceMobile"
                                                name="device"
                                                label="MOBILE"
                                                value="MOBILE"
                                                checked={values.device === 'MOBILE'}
                                                onChange={handleChange}
                                                style={{ marginLeft: '10px' }}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="deviceAll"
                                                name="device"
                                                label="ALL"
                                                value="ALL"
                                                checked={values.device === 'ALL'}
                                                onChange={handleChange}
                                                style={{ marginLeft: '10px' }}

                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">

                                    <Col md={6} >
                                        <Form.Group controlId="validationFormik03">
                                            <Form.Label>PICK DATE</Form.Label>
                                            <Form.Select
                                                name="pickDate"
                                                value={values.pickDate}
                                                onChange={handleChange}
                                            >
                                                <option value=''>SELECTED DATE</option>
                                                <option value='today'>TODAY</option>
                                                <option value='yesterday'>YESTERDAY</option>
                                                <option value='thisWeek'>THIS WEEK</option>
                                                <option value='lastWeek'>LAST WEEK</option>
                                                <option value='thisMonth'>THIS MONTH</option>
                                                <option value='lastMonth'>LAST MONTH</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "center" }} className="footer-btns">
                                    <Button type="submit" className="input_file_button">
                                        SUBMIT
                                    </Button>
                                </div>
                            </Form>
                        </Col>

                    </Row>
                </div>
                {isShowTable &&
                    <>
                        <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                            <div className="detail-heading"  >
                                <h6 className="text-white" style={{ backgroundColor: 'rgb(56 39 221)' }}>SELF REPORT</h6>
                            </div>



                            <div className="table-container" style={{ margin: "20px" }}>
                                {loading && (
                                    <div className="spinner-overlay">
                                        <Spinner animation="border" />
                                    </div>
                                )}
                                <Table bordered dataSource={bussinessReportData} columns={column} scroll={{ x: 525 }}
                                    pagination={false}
                                    onChange={handleTableChange}
                                />

                            </div>
                        </div>
                        <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                            <div className="detail-heading"  >
                                <h6 className="text-white" style={{ backgroundColor: 'rgb(56 39 221)', padding: "1rem" }}></h6>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "14px 0",
                                    alignItems: "center",
                                    padding: "0 10px",
                                }}
                                className="table-hader-container"
                            >
                                <div className="page-size">
                                    <span className="fw-bold">Show</span>
                                    <select onChange={(e) => setPageSize(e.target.value)}
                                        value={pageSize} className="selecte-page-size">
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </select>
                                    <span className="fw-bold">entries</span>
                                </div>

                                <div className="search_container">
                                    <Input
                                        placeholder="Search"
                                        prefix={<SearchOutlined />}
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                        className="search_input"
                                        style={{
                                            borderRadius: "5px",
                                            height: "40px",
                                        }}
                                    />
                                </div>

                            </div>



                            <div className="table-container" style={{ margin: "20px" }}>
                                {loading && (
                                    <div className="spinner-overlay">
                                        <Spinner animation="border" />
                                    </div>
                                )}
                                <Table bordered dataSource={bussinessReportList} columns={columns} scroll={{ x: 525 }}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: pageSize,
                                        showSizeChanger: false,
                                        total: bussinessReportList?.length,
                                        onChange: (page, pageSize) => {
                                            setCurrentPage(page);
                                        },
                                        showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                                    }}

                                    onChange={handleTableChange}
                                    summary={(pageData) => {
                                        let totalPlayPoint = 0;
                                        let totalWonPoint = 0;
                                        let totalEndPoint = 0;
                                        let totalMergin = 0;
                                        let totalNet = 0;
                                        let totalProfit = 0
                                        pageData.forEach((data) => {
                                            totalPlayPoint += data.totalPlayPoints;
                                            totalWonPoint += data.totalWinPoints;
                                            totalEndPoint += data.totalPlayPoints - data.totalWinPoints;
                                            if (bussinessReportRole == 'partner') {
                                                totalMergin += data.totalDistributorCommission
                                                totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - (data?.totalDistributorCommission || 0);
                                                totalProfit += data.totalPartnerCommission
                                            }
                                            if (bussinessReportRole == 'distributor') {
                                                totalMergin += data.totalSubDistributorCommission
                                                totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - (data?.totalSubDistributorCommission || 0);
                                                totalProfit += data.totalDistributorCommission
                                            }
                                            if (bussinessReportRole == 'sub-distributor') {
                                                totalMergin += data.totalAgentCommission
                                                totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - (data?.totalAgentCommission || 0);
                                                totalProfit += data.totalSubDistributorCommission
                                            }
                                            if (bussinessReportRole == 'agent') {
                                                totalProfit += data.totalAgentCommission
                                            }
                                            if (bussinessReportRole == 'user') {
                                                totalProfit += data.totalWinPoints
                                            }
                                        });
                                        return (
                                            <>
                                                <Table.Summary.Row style={{ backgroundColor: 'rgb(251 251 251)', fontWeight: 'bold' }}>
                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>
                                                        <Text>{totalPlayPoint}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5}>
                                                        <Text>{totalWonPoint}</Text>
                                                    </Table.Summary.Cell> <Table.Summary.Cell index={6}>
                                                        <Text>{totalEndPoint}</Text>
                                                    </Table.Summary.Cell>
                                                    {bussinessReportRole != 'agent' && bussinessReportRole != 'user' &&
                                                        <>
                                                            <Table.Summary.Cell index={7}>
                                                                <Text>{totalMergin}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8}>
                                                                <Text>{totalNet}</Text>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    }
                                                    <Table.Summary.Cell index={9}>
                                                        <Text>{totalProfit}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={10}>
                                                        <Text>0</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>

                                            </>
                                        );
                                    }}
                                />

                            </div>
                        </div>
                    </>
                }
            </Container>
        </main >
    );
};

export default BussinessReport;

