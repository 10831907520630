import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole } from "../../../utils/cookie";
import { MultiSelect } from "react-multi-select-component";

const UserFormModel = () => {
    const location = useLocation();
    const isEditMode = !!location?.state?.data;
    const params = useParams()
    const isView = params.id == "view" ? true : false
    const [userRole, setUserRole] = useState(getRole());
    const [initialValues, setInitialValues] = useState({
        id: '',
        userName: '',
        email: '',
        password: '',
        phone: '',
        games: [],
        ...((userRole !== 'superAdmin') && { verifyPassword: '' }),
        ...((userRole == 'admin' || userRole == 'partner') && { distributor: '', subDistributor: '', agent: '' }),
        ...((userRole == 'distributor') && { subDistributor: '', agent: '' }),
        ...((userRole == 'subDistributor' || userRole === 'superAdmin') && { agent: '' }),
        ...((userRole === 'superAdmin') && { admin: '', partner: '', distributor: '', subDistributor: '' }),

    })
    const [adminList, setAdminList] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [disributorList, setDistributorList] = useState([])
    const [subDisributorList, setSubDistributorList] = useState([])
    const [agentList, setAgentList] = useState([])

    const toast = useToast();
    const navigate = useNavigate();
    const [gameList, setGameList] = useState([]);
    const [games, setGames] = useState([]);
    const [gamesError, setGamesError] = useState(false);

    const getGameList = async () => {
        await apiAdminConfig.get("games/get").then((response) => {
            if (response?.data?.results?.length > 0) {
                setGameList(
                    response.data.results.map((list) => ({
                        label: list?.name ?? '',
                        value: list?.id
                    })) ?? []
                );
                if (location?.state?.data?.games?.length > 0) {
                    setGames(location?.state?.data?.games)
                }
            }
        }).catch((error) => {
            console.log("gameListError", error);
        });
    };

    useEffect(() => {
        getGameList();
    }, []);


    useEffect(() => {
        if (userRole == 'admin' || userRole == 'partner') {
            getDistributor();
        }
        if (userRole == 'distributor') {
            getSubDistributor();
        }
        if (userRole == 'subDistributor') {
            getAgent();
        }
        if (userRole == 'superAdmin') {
            getAdmin();
        }
        if (isEditMode || isView) {
            if (userRole == 'admin' || userRole == 'partner') {
                getSubDistributor(location?.state?.data?.distributor?.id)
                getAgent(location?.state?.data?.subDistributor?.id)
            } else if (userRole == 'distributor') {
                getAgent(location?.state?.data?.subDistributor?.id)
            } if (userRole == 'superAdmin') {
                getPartner(location?.state?.data?.admin?.id)
                getDistributor(location?.state?.data?.partner?.id ? location?.state?.data?.partner?.id : location?.state?.data?.admin?.id)
                getSubDistributor(location?.state?.data?.distributor?.id)
                getAgent(location?.state?.data?.subDistributor?.id)
            }
            setInitialValues({
                id: location?.state?.data?.id,
                userName: location?.state?.data?.userName,
                email: location?.state?.data?.email,
                phone: location?.state?.data?.phone,
                games: location?.state?.data?.games,
                verifyPassword: '',
                ...((userRole == 'admin' || userRole == 'partner') && { distributor: location?.state?.data?.distributor?.id, subDistributor: location?.state?.data?.subDistributor?.id, agent: location?.state?.data?.agent?.id }),
                ...((userRole == 'distributor') && { subDistributor: location?.state?.data?.subDistributor?.id, agent: location?.state?.data?.agent?.id }),
                ...((userRole == 'subDistributor') && { agent: location?.state?.data?.agent?.id }),
                ...((userRole == 'superAdmin') && { admin: location?.state?.data?.admin?.id, partner: location?.state?.data?.partner?.id, distributor: location?.state?.data?.distributor?.id, subDistributor: location?.state?.data?.subDistributor?.id, agent: location?.state?.data?.agent?.id }),
            })
        }
    }, [])



    const getAdmin = async (id = '') => {
        await apiAdminConfig.get('users/get?role=admin').then((response) => {
            if (response?.data?.results?.length > 0) {
                setAdminList(response?.data?.results)
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };


    const getSubDistributor = async (id = '') => {
        await apiAdminConfig.get(`users/list?userBy=subDistributor&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getAgent = async (id = '') => {
        await apiAdminConfig.get(`users/list?userBy=agent&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setAgentList(response?.data)
            } else {
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };



    const validationSchema = yup.object().shape({
        userName: yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('User Name is required'),
        password: isEditMode
            ? yup.string().nullable()
            : yup.string()
                .min(4, 'Minimum 4 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Password is required'),
        ...(userRole === 'admin' || userRole === 'partner') && {
            distributor: yup.string().required("Select Distributor a required"),
            subDistributor: yup.string().required("Select Sub Distributor a required"),
            agent: yup.string().required("Select Agent a required"),
        },
        ...(userRole === 'distributor') && {
            subDistributor: yup.string().required("Select Sub Distributor a required"),
            agent: yup.string().required("Select Agent a required"),
        },
        ...(userRole === 'subDistributor' || userRole === 'superAdmin') && {
            agent: yup.string().required("Select Agent a required"),
        },
        ...((userRole !== 'superAdmin') && { verifyPassword: yup.string().min(4).max(50).required("Verify Password is a required field") }),
        ...((userRole === 'superAdmin') && { distributor: yup.string().required("Select Distributor is required"), subDistributor: yup.string().required("Select Sub Distributor is required"), admin: yup.string().required("Select Admin is required") }),

    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, touched, validateForm } =
        useFormik({
            initialValues: initialValues,
            validationSchema,
            enableReinitialize: true,

            onSubmit: async () => {

                if (games.length === 0) {
                    setGamesError(true);
                    return;
                }

                setGamesError(false);
                values['phone'] = values.phone.toString();
                values['games'] = games;

                if (!values.partner) delete values.partner


                if (isEditMode) {

                    await apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, values).then((response) => {
                        if (response?.status === 200) {
                            navigate('/WinLucky/user');
                            toast({
                                message: 'Users updated successfully',
                                bg: 'success'
                            });
                        } else {
                            toast({
                                message: response?.data?.message,
                                bg: 'danger'
                            });
                        }
                    }).catch((error) => {
                        const { response } = error;
                        toast({
                            message: response?.data?.message,
                            bg: "danger",
                        });
                    });

                } else {

                    await apiAdminConfig.post(`users/add-user`, values).then((response) => {
                        if (response?.status == 201) {
                            navigate('/WinLucky/user')
                            toast({
                                message: 'User Added Successfully',
                                bg: 'success'
                            });
                        } else {
                            toast({
                                message: response?.data?.message,
                                bg: 'danger'
                            });
                        }
                    }).catch((error) => {
                        const { response } = error
                        toast({
                            message: response?.data?.message,
                            bg: "danger",
                        });
                    })
                }

            },
        });

    const getDistributor = async (id = '') => {
        const parentId = (id == "" && !!values.admin) ? values.admin : id
        await apiAdminConfig.get(`users/list?userBy=distributor&userId=${parentId}`).then((response) => {
            if (response?.data?.length > 0) {
                setDistributorList(response?.data)
            } else {
                setDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };
    const getPartner = async (id) => {
        setFieldValue("partner", '')
        setFieldValue("distributor", '')


        await apiAdminConfig.get(`users/list?userBy=partner&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setPartnerList(response?.data)
            } else {
                setPartnerList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })

        await apiAdminConfig.get(`users/list?userBy=distributor&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setDistributorList(response?.data)
            } else {
                setDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })

    }


    const handleGameSelection = (selectedGames) => {
        setGames(selectedGames.map(game => game.value));
        setGamesError(false);
    };

    return (
        <main id="events">
            <Container className="admin_padding">
                {/* <div className="admin_add_form">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                        }}
                        className="table-hader-container"
                    >
                        <div>
                            <h3 className="table-hader-name">{!isView ? (isEditMode ? "Edit" : "Add") : 'View'} User</h3>
                        </div>
                        <Link to="/user" className="page_add_btn">
                            Back
                        </Link>
                    </div> */}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // margin: "14px 0",
                        alignItems: "center",
                    }}
                    className="table-hader-container"
                >
                    <div className=" breadcumb">
                        <h1 >{!isView ? (isEditMode ? 'EDIT USER' : 'CREATE USER') : 'View USER'}</h1>
                        <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / {!isView ? (isEditMode ? 'EDIT USER' : 'CREATE USER') : 'View USER'}</p>
                    </div>

                    <Link to="/WinLucky/user" className="page_add_btn">
                        Back
                    </Link>
                </div>
                <div className="admin_form">
                    <div className="detail-heading">
                        <h6 className="text-white">ENTER DETAILS</h6>
                    </div>
                    <Row className="admin_add_form">
                        {/* <Row> */}
                        {
                            (userRole == 'superAdmin') && <>

                                <Row >
                                    <Col className="mb-3" md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Select Admin<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="admin"
                                                value={values.admin}
                                                onChange={(e) => {
                                                    getPartner(e.target.value)
                                                    setFieldValue("admin", e.target.value)
                                                }}
                                                isInvalid={!!errors.admin && touched.admin}
                                            >

                                                <option value=''>Select Admin</option>
                                                {adminList && adminList.length > 0 && adminList.map((item, index) => {
                                                    return (
                                                        <option value={item?.id} >{item?.userName}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.admin}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {!!values.admin &&
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Select Partner
                                                </Form.Label>
                                                <Form.Select
                                                    type="text"
                                                    name="partner"
                                                    value={values.partner}
                                                    onChange={(e) => {
                                                        setFieldValue("partner", e.target.value)
                                                        getDistributor(e.target.value)
                                                        setFieldValue("distributor", '')
                                                        setDistributorList([])

                                                    }}
                                                >
                                                    <option value=''>Select Partner</option>
                                                    {partnerList && partnerList.length > 0 && partnerList.map((item, index) => {
                                                        return (
                                                            <option value={item?.id} >{item?.userName}</option>
                                                        )
                                                    })}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>
                            </>}
                        {
                            (userRole == 'admin' || userRole == 'partner' || !!values.admin) && (
                                // <Row>
                                <Col className="mb-3" md={6}>
                                    <Form.Group controlId="validationFormik01">
                                        <Form.Label>
                                            Select Distributor<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            type="text"
                                            name="distributor"
                                            value={values.distributor}
                                            disabled={isView}
                                            onChange={(e) => {
                                                setFieldValue("distributor", e.target.value)
                                                if (e.target.value) {
                                                    getSubDistributor(e.target.value)
                                                } else {
                                                    setSubDistributorList([])
                                                    setAgentList([])
                                                    setFieldValue("subDistributor", '')
                                                    setFieldValue("agent", '')
                                                }
                                            }}
                                            isInvalid={!!errors.distributor && touched.distributor}
                                        >
                                            <option value=''>Select Distributor</option>
                                            {disributorList && disributorList.length > 0 && disributorList.map((item, index) => {
                                                return (
                                                    <option value={item?.id} >{item?.userName}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.distributor}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                // </Row>
                            )
                        }
                        {
                            (!!values.distributor || userRole == 'distributor') &&
                            <Col className="mb-3" md={6}>
                                <Form.Group controlId="validationFormik01">
                                    <Form.Label>
                                        Select Sub Distributor<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        type="text"
                                        name="subDistributor"
                                        value={values.subDistributor}
                                        disabled={isView}
                                        onChange={(e) => {
                                            setFieldValue("subDistributor", e.target.value)
                                            if (e.target.value) {
                                                getAgent(e.target.value)
                                            } else {
                                                setAgentList([])
                                                setFieldValue("agent", '')
                                            }
                                        }}
                                        isInvalid={!!errors.subDistributor && touched.subDistributor}
                                    >
                                        <option value="">Select Sub Distributor</option>
                                        {subDisributorList && subDisributorList.length > 0 && subDisributorList.map((item, index) => {
                                            return (
                                                <option value={item?.id} >{item?.userName}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subDistributor}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        {/* </Row> */}
                        {
                            (!!values.subDistributor || userRole == 'subDistributor') &&
                            <Row >
                                <Col className="mb-3" md={6}>
                                    <Form.Group controlId="validationFormik01">
                                        <Form.Label>
                                            Select Agent<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            type="text"
                                            name="agent"
                                            value={values.agent}
                                            disabled={isView}
                                            onChange={(e) => {
                                                setFieldValue("agent", e.target.value)
                                            }}
                                            isInvalid={!!errors.agent && touched.agent}
                                        >
                                            <option value=''>Select Agent</option>
                                            {agentList && agentList.length > 0 && agentList.map((item, index) => {
                                                return (
                                                    <option value={item?.id} >{item?.userName}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.agent}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>
                        }
                        <Row>
                            <Col>
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row >
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    User Name<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="userName"
                                                    value={values.userName}
                                                    placeholder="Enter User Name"
                                                    disabled={isView}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.userName && touched.userName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.userName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Email
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    placeholder="Enter Email"
                                                    disabled={isView}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Phone No
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="phone"
                                                    placeholder="Enter Phone No"
                                                    disabled={isView}
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {
                                            !isView && (
                                                <Col className="mb-3">
                                                    <Form.Group controlId="validationFormik01">
                                                        <Form.Label>
                                                            Password<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            name="password"
                                                            placeholder="Enter Password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            isInvalid={
                                                                !!errors.password && touched.password
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }

                                        <Col md={6} className="mb-3">
                                            <Form.Group controlId="validationFormik07">
                                                <Form.Label>ELIGIBLE GAME<span className="text-danger">*</span></Form.Label>
                                                <MultiSelect
                                                    options={gameList}
                                                    value={games.map((val) => gameList.find((list) => list.value === val))}
                                                    onChange={handleGameSelection}
                                                    labelledBy="Select Game"
                                                />
                                                {gamesError && <p className="text-danger">At least one game is required</p>}

                                            </Form.Group>
                                        </Col>
                                        {
                                            userRole !== 'superAdmin' &&

                                            <Col md={6} className="mb-3">
                                                <Form.Group controlId="validationFormik08">
                                                    <Form.Label>{userRole.toUpperCase()} TRANSACTION PASSWORD<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        name="verifyPassword"
                                                        placeholder="Enter Verify Password"
                                                        value={values.verifyPassword}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.verifyPassword && touched.verifyPassword}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.verifyPassword}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        }
                                    </Row>


                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        // className="footer-btns"
                                    >
                                        {!isView &&
                                            <Button type="submit" className="input_file_button">
                                                {isEditMode ? 'UPDATE USER' : 'CREATE USER'}
                                            </Button>
                                        }
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                </div>
            </Container>
        </main>
    );
};

export default UserFormModel;
