import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { apiAdminConfig } from "../../utils/api";
import { getRole } from "../../utils/cookie";
import { Table, Input, Typography } from 'antd';
import moment from "moment";

const UserDetail = () => {
    const [userDeatailList, setUserDeatailList] = React.useState([]);
    const [userRole, setUserRole] = React.useState(getRole());
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    // const [search, setSearch] = React.useState("");
    const [gameList, setGameList] = useState([]);



    const navigate = useNavigate();
    const location =useLocation()
    const id = location.state._id

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const initialValues = {
        game: '',
    };

    const getGameList = async () => {
        await apiAdminConfig.get("games/get").then((response) => {
            if (response?.data?.results?.length > 0) {
                setGameList(
                    response.data.results.map((list) => ({
                        label: list?.name ?? '',
                        value: list?.id
                    })) ?? []
                );

            }
        }).catch((error) => {
            console.log("gameListError", error);
        });
    };

    useEffect(() => {
        getGameList();
    }, []);

   


    const getUserDeatailList = async ( game = '') => {
        setLoading(true);
        try {
            const url =  `users/game-report?userId=${id}&game=${game}` 

            // const url = game ? `games/transactions?search=${search}&userId=${id}&game=${game}` : `games/transactions?search=${search}&userId=${id}`
            const response = await apiAdminConfig.get(
                url
            );

            if (response?.status === 200) {
                setUserDeatailList(response?.data);

            }
        } catch (error) {
            console.log("ListError", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getUserDeatailList(); // Initial load
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {(currentPage - 1) * pageSize + index + 1}
                    </>
                )
            }
        },
        // {
        //     title: 'User Name',
        //     dataIndex: 'userName',
        //     key: 'user Name',
        // },
       
        {
            title: 'Before',
            dataIndex: 'beforePoints',
            key: 'Before Point',
            width: 150,
            render: text => text || '-',
        },
        {
            title: 'Play',
            dataIndex: 'playPoints',
            key: 'Play',
            render: text => text || '-',
        },
        {
            title: 'Won',
            dataIndex: 'win',
            key: 'Win',
            render: (_, record) => record?.playgame?.winNumber || "0",
        },
        {
            title: 'After',
            dataIndex: 'afterPoints',
            key: 'After',
            render: text => text || '-',
        },

        {
            title: 'Game Id',
            dataIndex: 'referId',
            key: 'Game Id',
            render: text => text || '-',
        },

        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'Date',
            render: text => text ? moment(text).format('DD-MM-YYYY') : '-',
            width: 250,
        },
    ];
    const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async () => {
            const {  game } = values;
            await getUserDeatailList( game);
        },
    });
    return (
        <main>

            <Container className="admin_padding">
                <div style={{ display: "flex", justifyContent: "space-between", margin: "14px 0", alignItems: "center", padding: "0 10px" }} className="table-hader-container">
                    <div className=" breadcumb">
                        <h1 > USER DETAIL</h1>
                        <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> /   USER DETAIL</p>
                    </div>

                </div>
                <div className="admin_form" style={{ width: '98%', marginBottom: '20px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#f33120' }}> FILTER</h6>
                    </div>
                    <Row className="admin_add_form">

                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>
                                

                                <Row>
                                    <Col className="mb-3" md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Select Game
                                            </Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="game"
                                                value={values.game}
                                                onChange={(e) => {
                                                    setFieldValue("game", e.target.value)
                                                }}
                                            >
                                                <option value=''>Select Game</option>
                                                {gameList && gameList.length > 0 && gameList.map((item, index) => {
                                                    return (
                                                        <option value={item?.value} >{item?.label}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div style={{ display: "flex", justifyContent: "center" }} className="footer-btns">
                                    <Button type="submit" className="input_file_button">
                                        SUBMIT
                                    </Button>
                                </div>
                            </Form>
                        </Col>

                    </Row>
                </div>

                <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: 'rgb(56 39 221)' }}>User Detail</h6>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div className="page-size">
                            <span className="fw-bold">Show</span>
                            <select onChange={(e) => setPageSize(e.target.value)}
                                value={pageSize} className="selecte-page-size">
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                            <span className="fw-bold">entries</span>
                        </div>

                        {/* <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                }}
                            />
                        </div> */}

                    </div>
                    <div className="table-container" style={{ margin: "20px" }}>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        <Table bordered dataSource={userDeatailList} columns={columns} scroll={{ x: 525 }}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                total: userDeatailList?.length,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                },
                                showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                            }}

                            onChange={handleTableChange}

                        />

                    </div>
                </div>
            </Container>
        </main >
    );
};

export default UserDetail;

