
// import "../style.scss";
// import React, { useState, useEffect } from "react";
// import { useLocation, Link } from "react-router-dom";
// import moment from "moment";
// import { apiAdminConfig } from "../../../utils/api";
// import { convertArrayToString } from "../../../utils/helper";
// import { Form } from "react-bootstrap";


// const RegisterViewModel = () => {
//   const location = useLocation();
//   const [registerDetail, setRegisterDetail] = useState(null)
//   const [lockStatus, setLockStatus] = useState(false)


//   const getRegisterDetail = async () => {
//     await apiAdminConfig.get(`users/get/${location?.state?.data?.id}`).then((response) => {
//       if (response?.status == 200) {
//         setRegisterDetail(response?.data)
//         setLockStatus(response?.data?.lockStatus == 'locked' ? true : false)
//       }
//     }).catch((error) => {
//       console.log("error", error);
//     })
//   }

//   useEffect(() => {
//     getRegisterDetail()
//   }, [location]);

//   const handleLockStatus = (e) => {
//     let status = e.target.checked ? 'locked' : 'unlocked';
//     setLockStatus(e.target.checked)
//     apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, { lockStatus: status }).then((response) => {
//         if (response?.status == 200) {
//           getRegisterDetail()
//         }
//     }).catch((error) => {
//         console.log("error", error);
//     })
// }

//   return <>
//     <main id="partnerView">
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           margin: "14px 0",
//           alignItems: "center",
//         }}
//         className="table-hader-container"
//       >
//         <Link to="/WinLucky/admin" className="page_add_btn" style={{ marginLeft: "20px" }}>
//           Back
//         </Link>
//       </div>
//       <div className='d-flex mt-3' >
//         <div className='card text-black  mb-3'>
//           <div className="card-body">
//             <h5 className="card-title">Admin Detail</h5>
//             <hr></hr>
//             <p>
//               <span className="label">User Name</span>
//               <span className="colon">:</span>
//               <span className="value">{registerDetail?.userName || '-'}</span>
//             </p>
//             <p>
//               <span className="label">Point</span>
//               <span className="colon">:</span>
//               <span className="value">{registerDetail?.points || '0'}</span>
//             </p>
//             <p>
//               <span className="label">Email</span>
//               <span className="colon">:</span>
//               <span className="value">{registerDetail?.email || '-'}</span>
//             </p>
//             <p>
//               <span className="label">Phone No</span>
//               <span className="colon">:</span>
//               <span className="value">{registerDetail?.phone || '-'}</span>
//             </p>
//             <p>
//               <span className="label">Last Login</span>
//               <span className="colon">:</span>
//               <span className="value">{moment(registerDetail?.lastLogin).format('DD-MM-YYYY') || '-'}</span>
//             </p>
//             <p>
//               <span className="label">Locked Status</span>
//               <span className="colon">:</span>
//               <span className="value">{registerDetail?.lockStatus || '-'}</span>
//             </p>
//             <p>
//               <span className="label">Locked By</span>
//               <span className="colon">:</span>
//               <span className="value">{registerDetail?.lockedBy?.userName || 'NULL'}</span>
//             </p>
//             <p>
//               <span className="label">Eligible Game</span>
//               <span className="colon">:</span>
//               <span className="value">{
//                 registerDetail?.games?.length > 0 ? convertArrayToString(registerDetail?.games?.map((item) => item.name)) : '-'
//               }</span>
//             </p>
//             <p>
//               <span className="label">Lock</span>
//               <span className="colon">:</span>
//               <label className="switch">
//                 <Form.Check
//                   type="switch"
//                   id="custom-switch"
//                   className="custom-switch"
//                   checked={lockStatus}
//                   onChange={(e) => handleLockStatus(e)}
//                 />
//               </label>
//             </p>
//           </div>
//         </div>
//       </div>
//     </main >
//   </>
// }

// export default RegisterViewModel




import "../style.scss";
import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { apiAdminConfig } from "../../../utils/api";
import moment from "moment";
import { convertArrayToString } from "../../../utils/helper";
import { Form, Modal, Button, Container , Row, Col} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup"; // for form validation
import { MultiSelect } from "react-multi-select-component";
import useToast from "../../../provider/Toast/useToast";

const RegisterViewModel = () => {
  const location = useLocation();
  const toast = useToast();

  const [registerDetail, setRegisterDetail] = useState(null);
  const [lockStatus, setLockStatus] = useState(false);
  const [games, setGames] = useState([]);
  const [gameList, setGameList] = useState([]);


  // States to manage the visibility of modals
  const [showLoginPasswordModal, setShowLoginPasswordModal] = useState(false);
  const [showTransactionPasswordModal, setShowTransactionPasswordModal] = useState(false);
  const [showReportPasswordModal, setShowReportPasswordModal] = useState(false);
  const [showCommissionPercentagedModal, setShowCommissionPercentagedModal] = useState(false);
  const navigate = useNavigate();
  const[allGame,setAllGame] = useState([]);

  const getGameList = async () => {
    await apiAdminConfig.get("games/get").then((response) => {
      if (response?.data?.results?.length > 0) {
        setAllGame(
          response.data.results
        );

      }
    }).catch((error) => {
      console.log("gameListError", error);
    });
  };

  useEffect(() => {
    getGameList();
  }, []);

  const getgameName = (id) => {
    if (!id) return '-';  
   
    const game = allGame.find((data) => data.id === id);
    
    if (!game) {
      return '-'; 
    }
    
    return game.name;
  };



  const getRegisterDetail = async () => {
    await apiAdminConfig
      .get(`users/get/${location?.state?.data?.id}`)
      .then((response) => {
        if (response?.status === 200) {
          setRegisterDetail(response?.data);
          console.log("response",response.data?.games)
          
          setGames(response?.data?.games?.map((list) => list.id) ?? []);
          setGameList(
            response?.data?.games.map((list) => ({
              label: list?.name ?? '',
              value: list?.id
            })) ?? []
          );
          setLockStatus(response?.data?.lockStatus === "locked" ? true : false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getRegisterDetail();
  }, [location]);
  

  const handleLockStatus = (e) => {
    let status = e.target.checked ? "locked" : "unlocked";
    setLockStatus(e.target.checked);
    apiAdminConfig
      .put(`users/update/${location?.state?.data?.id}`, { lockStatus: status })
      .then((response) => {
        if (response?.status === 200) {
          getRegisterDetail();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // Validation Schema for each password type
  const passwordValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handlePasswordReset = async (values, { setSubmitting, setFieldError }, passwordType) => {
    try {
      let obj ={}
      if (passwordType === "login") {
       obj = {
        password: values.newPassword,
        }
      } else if (passwordType === "transaction") {
        obj = {
          transactionsPassword: values.newPassword,
        }
      } else if (passwordType === "report") {
        obj = {
          reportPassword: values.newPassword,
        }
      }
      const response = await apiAdminConfig.put(
        `users/update/${registerDetail?._id}`,
       obj
      );

      if (response?.status === 200) {
        // Close the respective modal on successful password reset
        if (passwordType === "login") {
          setShowLoginPasswordModal(false);
        } else if (passwordType === "transaction") {
          setShowTransactionPasswordModal(false);
        } else if (passwordType === "report") {
          setShowReportPasswordModal(false);
        }
        toast({
          message: 'Password Upadate successfully',
          bg: 'success'
        });
      }else{
        toast({
          message: response?.data?.message,
          bg: "danger",
        });
      }
    } catch (error) {
      console.error(`${passwordType} password reset failed:`, error);
      setFieldError("newPassword", `Failed to reset ${passwordType} password. Please try again.`);
    } finally {
      setSubmitting(false);
    }
  };

  const changePercentage = (e, index) => {
    const newPercentage = [...registerDetail?.gameWisePercentage];
    newPercentage[index].percentage = e.target.value;
    setRegisterDetail((prevState) => ({
      ...prevState,
      gameWisePercentage: newPercentage
    }));
  };

  const handlePercentageChange = async(index) => {
    const response = await apiAdminConfig.put(
      `users/change-percentage`,
      {
        "percentage":registerDetail?.gameWisePercentage[index]?.percentage,
        "gameId":registerDetail?.gameWisePercentage[index]?._id,
        "userId":registerDetail?._id
    }
    );

    if (response?.status === 200) {
        setShowCommissionPercentagedModal(false);
        toast({
          message: 'Percentage Update successfully',
          bg: 'success'
        });
    }else{
      toast({
        message: response?.data?.message,
        bg: "danger",
      });
    }
  }

  const handleGameSelection = (selectedGames) => {
    console.log("selectedGames",selectedGames)
    setGames(selectedGames.map(game => game?.value));
  };

  const changeAligibleGame =async()=>{
    const response = await apiAdminConfig.put(
      `users/update/${registerDetail?._id}`,
     {games:games}
    );
    if (response?.status === 200) {
      toast({
        message: 'Change Aligible Game successfully',
        bg: 'success'
      });
      getRegisterDetail()
 
  }else{
    toast({
      message: response?.data?.message,
      bg: "danger",
    });
  }
  }
  return (
    <>
      <main id="events">
        <Container className="admin_padding">

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div className=" breadcumb">
              <h1>{registerDetail?.userName}-PROFILE</h1>
              <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / Profile</p>
            </div>

            {/* <Link to="/WinLucky/partner" className="page_add_btn">
              Back
            </Link> */}
          </div>
          <div className="admin_form">
            <div className="detail-heading">
              <h6 className="text-white" style={{backgroundColor:'rgb(255 91 85)'}}>EDIT ELIGIBLE GAME</h6>
            </div>
            <Row className="admin_add_form">
              <Col>
                {/* <Form noValidate onSubmit={handleSubmit}> */}
                  <Row className="mb-3" style={{justifyContent:'center',marginTop:'10px'}}>
                    <Col md={6} className="mb-3">
                      <Form.Group >
                        <Form.Label>ELIGIBLE GAME</Form.Label>
                        <MultiSelect
                          options={gameList}
                          value={games.map((val) => gameList?.find((list) => list?.value === val))}
                          onChange={handleGameSelection}
                          labelledBy="Select Game"
                        />

                      </Form.Group>
                    </Col>

                  </Row>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >

                    <Button type="submit" className="input_file_button" onClick={()=>{changeAligibleGame()}}>
                      CHANGE ALIGIBLE GAME
                    </Button>

                  </div>
                {/* </Form> */}
              </Col>
            </Row>
          </div>
          <div className="admin_form" style={{marginTop:'20px'}}>
            <div className="detail-heading">
              <h6 className="text-white" style={{ backgroundColor: 'rgb(38 133 227)', padding: "1rem" }}>FULL DETAIL</h6>
            </div>
          <div>
            <div className=" text-black mb-3 full-width-card">
              <div className="card-body ">
                {/* <h5 className="card-title">Partner Detail</h5>
                <hr /> */}

                {/* Table for partner details */}
                
                <table className="table table-bordered detail-heading" >
                  <tbody>
                    <tr>
                      <td className="label">User Name</td>
                      <td className="values">{registerDetail?.userName || "-"}</td>
                    </tr>
                    <tr>
                      <td className="label">Point</td>
                      <td>{registerDetail?.points || "0"}</td>
                    </tr>
                    <tr>
                      <td className="label">CURRENT INR</td>
                      <td>{registerDetail?.points || "0"}</td>
                    </tr>
                    <tr>
                      <td className="label">Email</td>
                      <td>{registerDetail?.email || "-"}</td>
                    </tr>
                    <tr>
                      <td className="label">Share Percentage</td>
                      <td>
                        <Button style={{backgroundColor:'black', color:'white',border:'none'}} onClick={() => setShowCommissionPercentagedModal(true)}>
                          COMMISSION PERCENTAGE
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Phone No</td>
                      <td>{registerDetail?.phone || "-"}</td>
                    </tr>
                   
                    <tr>
                      <td className="label">Last Login</td>
                      <td>{moment(registerDetail?.lastLogin).format("DD-MM-YYYY") || "-"}</td>
                    </tr>
                    <tr>
                      <td className="label">Locked Status</td>
                      <td>{registerDetail?.lockStatus || "-"}</td>
                    </tr>
                    <tr>
                      <td className="label">Locked By</td>
                      <td>{registerDetail?.lockedBy?.userName || "NULL"}</td>
                    </tr>
                    <tr>
                      <td className="label">TOTAL USER IN HAND</td>
                      <td>{registerDetail?.totalUserInHand || "0"}</td>
                    </tr>
                    <tr>
                      <td className="label">Eligible Game</td>
                      <td>
                        {registerDetail?.games?.length > 0
                          ? convertArrayToString(registerDetail?.games?.map((item) => item.name))
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Lock</td>
                      <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={lockStatus}
                          onChange={(e) => handleLockStatus(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Login Password Reset</td>
                      <td>
                        <Button variant="warning" onClick={() => setShowLoginPasswordModal(true)}>
                          RESET LOGIN PASSWORD
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Transaction Password Reset</td>
                      <td>
                        <Button variant="warning" onClick={() => setShowTransactionPasswordModal(true)} >
                          RESET TRANSACTION PASSWORD
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Report Password Reset</td>
                      <td>
                        <Button variant="warning" onClick={() => setShowReportPasswordModal(true)}>
                          RESET REPORT PASSWORD
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>

        </Container>
      </main>
     
      <Modal show={showLoginPasswordModal} onHide={() => setShowLoginPasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Login Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validationSchema={passwordValidationSchema}
            onSubmit={(values, actions) => handlePasswordReset(values, actions, "login")}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.newPassword && errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="confirmPassword" style={{marginTop:'10px'}}>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.confirmPassword && errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>

                <Button type="submit" disabled={isSubmitting} style={{marginTop:'10px'}}>RESET</Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Transaction Password Reset Modal */}
      <Modal show={showTransactionPasswordModal} onHide={() => setShowTransactionPasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Transaction Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validationSchema={passwordValidationSchema}
            onSubmit={(values, actions) => handlePasswordReset(values, actions, "transaction")}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.newPassword && errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="confirmPassword" style={{marginTop:'10px'}}>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.confirmPassword && errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>

                <Button type="submit" disabled={isSubmitting} style={{marginTop:'10px'}}>RESET</Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Report Password Reset Modal */}
      <Modal show={showReportPasswordModal} onHide={() => setShowReportPasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Report Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validationSchema={passwordValidationSchema}
            onSubmit={(values, actions) => handlePasswordReset(values, actions, "report")}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.newPassword && errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="confirmPassword" style={{marginTop:'10px'}}>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.confirmPassword && errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>

                <Button type="submit" disabled={isSubmitting} style={{marginTop:'10px'}}>RESET</Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>


      <Modal show={showCommissionPercentagedModal} onHide={() => setShowCommissionPercentagedModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Game Percentage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            {registerDetail?.gameWisePercentage?.map((data, index) => (
              <div key={index} className="d-flex justify-content-between align-items-center mb-5 w-100">
               <p className="mb-0" style={{ color: 'blue' }}>{ getgameName(data?.gameId)}</p>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={data.percentage}
                    style={{ width: '120px', marginRight: '10px' }}
                    onChange={(e) => { changePercentage(e, index) }}
                  />
                  <Button style={{ backgroundColor: 'red', border: 'none' }} onClick={() => handlePercentageChange(index)} >Change Percentage</Button>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      {/* Additional Modals (Transaction and Report) follow a similar structure */}
    </>
  );
};

export default RegisterViewModel;











