import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole } from "../../../utils/cookie";
import { MultiSelect } from "react-multi-select-component";


const PartnerFormModel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams()
  const isView = params.id == "view" ? true : false
  const isEditMode = !!location?.state?.data;
  const [userRole, setUserRole] = React.useState(getRole())

  const [gameList, setGameList] = useState([]);
  const [games, setGames] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [gamesError, setGamesError] = useState(false);

  const getGameList = async () => {
    await apiAdminConfig.get("games/get").then((response) => {
      if (response?.data?.results?.length > 0) {
        setGameList(
          response.data.results.map((list) => ({
            label: list?.name ?? '',
            value: list?.id
          })) ?? []
        );
        const newPercentages = response.data.results.map((list) => {
          const percentageData = location?.state?.data?.gameWisePercentage.find((data) => {
            return data?.gameId == list?.id
          })
          if (!!percentageData) {
            return {
              percentage: percentageData.percentage,
              gameId: percentageData?.gameId
            }
          } else {
            return {
              percentage: '',
              gameId: list?.id
            }
          }

        }) ?? []
        setPercentages(newPercentages);
        setFieldValue("percentages", newPercentages);

        if (location?.state?.data?.games?.length > 0) {
          setGames(location?.state?.data?.games)
        }
      }
    }).catch((error) => {
      console.log("gameListError", error);
    });
  };

  useEffect(() => {
    getGameList();
  }, []);


  const [initialValues, setInitialValues] = useState({
    userName: '',
    email: '',
    password: '',
    phone: '',
    reportPassword: '',
    transactionsPassword: '',
    ...((userRole !== 'superAdmin') && { verifyPassword: '' }),
    games: [],
    ...((userRole === 'superAdmin') && { admin: '' }),
    percentages: gameList.map(game => ({ gameId: game.value, percentage: '' })),
  });
  const [adminList, setAdminList] = useState([])
  useEffect(() => {
    if (isEditMode || isView) {
      setInitialValues({
        ...location?.state?.data,
        admin: location?.state?.data?.admin.id,
        games: location?.state?.data?.games,
        reportPassword: '',
        transactionsPassword: '',
        verifyPassword: ''
      });
      // setGames(location?.state?.data?.games)
    }
  }, [location, isEditMode]);

  const getAdmin = async () => {
    await apiAdminConfig.get('users/get?role=admin').then((response) => {
      if (response?.data?.results?.length > 0) {
        setAdminList(response?.data?.results)
      }
    }).catch((error) => {
      console.log("certificateError", error);
    })
  };

  React.useEffect(() => {
    const data = localStorage.getItem('userDetail')
    const userData = JSON.parse(data);

    if (userData?.role == 'superAdmin') {
      getAdmin();
    }
  }, []);

  const validationSchema = yup.object().shape({
    userName: yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('User Name is required'),
    password: isEditMode
      ? yup.string().nullable()
      : yup.string()
        .min(4, 'Minimum 4 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    reportPassword: isEditMode
      ? yup.string().nullable()
      : yup.string().min(4).max(50).required("Report Password is a required field"),
    transactionsPassword: isEditMode
      ? yup.string().nullable()
      : yup.string().min(4).max(50).required("Transactions Password is a required field"),
    ...((userRole !== 'superAdmin') && { verifyPassword: yup.string().min(4).max(50).required("Verify Password is a required field") }),
    ...((userRole === 'superAdmin') && { admin: yup.string().required("Select Admin is required") }),
    percentages: yup.array().of(
      yup.object().shape({
        percentage: yup.number().required("Percentage is required").min(0, "Percentage cannot be negative").max(100, "Percentage cannot exceed 100")
      })
    ).required("All percentages must be filled")
  });

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      if (games.length === 0) {
        setGamesError(true);
        return;
      }

      const newPercentage = []
      percentages.forEach((item) => {
        if (!!item.percentage) {
          newPercentage.push(item)
        }
      })

      delete values.percentages
      setGamesError(false);
      values['phone'] = values.phone.toString();
      values['gameWisePercentage'] = Object.values(newPercentage);
      values['games'] = games;
      if (isEditMode) {
        await apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, values).then((response) => {
          if (response?.status === 200) {
            navigate('/WinLucky/partner');
            toast({
              message: 'Partner updated successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });

      } else {

        await apiAdminConfig.post(`users/add-partner`, values).then((response) => {
          if (response?.status === 201) {
            navigate('/WinLucky/partner');
            toast({
              message: 'Partner added successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });
      }
    },
  });

  const handleGameSelection = (selectedGames) => {
    setGames(selectedGames.map(game => game?.value));
    setGamesError(false);
  };

  const handlePercentageChange = (gameId, value) => {

    // setPercentages(prev =>
    //   prev?.map(item =>
    //     item?.gameId === gameId ? { ...item, "percentage": value } : item
    //   )
    // );

    const newPercentages = percentages.map(item =>
      item.gameId === gameId ? { ...item, percentage: value } : item
    );

    setPercentages(newPercentages);
    setFieldValue("percentages", newPercentages);

  };

  return (
    <main id="events">
      <Container className="admin_padding">
        {/* <div className="admin_form">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div>
              <h3 className="table-hader-name">{!isView ? (isEditMode ? 'Edit Partner' : 'Add Partner') : "View Partner"}</h3>
            </div>
            <Link to="/partner" className="page_add_btn">
              Back
            </Link>
          </div>
 */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // margin: "14px 0",
            alignItems: "center",
          }}
          className="table-hader-container"
        >
          <div className=" breadcumb">
              <h1 >{!isView ? (isEditMode ? 'EDIT PARTNER' : 'CREATE PARTNER') : 'View PARTNER'}</h1>
              <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / {!isView ? (isEditMode ? 'EDIT PARTNER' : 'CREATE PARTNER') : 'View PARTNER'}</p>
            </div>
          
          <Link to="/WinLucky/partner" className="page_add_btn">
            Back
          </Link>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">ENTER DETAILS</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                {
                  (userRole == 'superAdmin') && <>

                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>
                            Select Admin<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            type="text"
                            name="admin"
                            value={values.admin}
                            onChange={(e) => {
                              setFieldValue("admin", e.target.value)
                            }}
                            isInvalid={!!errors.admin && touched.admin}
                          >

                            <option value=''>Select Admin</option>
                            {adminList && adminList.length > 0 && adminList.map((item, index) => {
                              return (
                                <option value={item?.id} >{item?.userName}</option>
                              )
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.admin}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>}
                <Row>
                  <Col className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        User Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        value={values.userName}
                        onChange={handleChange}
                        disabled={isView}
                        placeholder="Enter User Name"
                        isInvalid={!!errors.userName && touched.userName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.userName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        disabled={isView}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Phone No
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="phone"
                        placeholder="Enter Phone No"
                        disabled={isView}
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  {
                    !isView && (<Col md={6}>
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>
                          Password
                          {!isEditMode && <span className="text-danger">*</span>}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password && touched.password}
                        // placeholder={isEditMode ? 'Leave blank to keep unchanged' : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>)
                  }

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik05">
                      <Form.Label>Transactions Password{!isEditMode && <span className="text-danger">*</span>}</Form.Label>
                      <Form.Control
                        type="password"
                        name="transactionsPassword"
                        placeholder="Enter Transactions Password"
                        value={values.transactionsPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.transactionsPassword && touched.transactionsPassword}
                      />
                      <Form.Control.Feedback type="invalid">{errors.transactionsPassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik06">
                      <Form.Label>Report Password{!isEditMode && <span className="text-danger">*</span>}</Form.Label>
                      <Form.Control
                        type="password"
                        name="reportPassword"
                        placeholder="Enter Report Password"
                        value={values.reportPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.reportPassword && touched.reportPassword}
                      />
                      <Form.Control.Feedback type="invalid">{errors.reportPassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {gameList.length > 0 && (
                    <>
                      <Form.Label>SET PERCENTAGE</Form.Label>
                      <Row style={{ height: '150px', overflow: 'auto', border: '1px solid #ccc', padding: '10px', marginLeft: "7px" }} className="mb-3">
                        {gameList.map((game, index) => (
                          <Col md={6} className="mb-3" key={game?.value}>
                            <Form.Group controlId={`validationFormikPercentage${game?.value}`}>
                              <Form.Label className="label-color">{game?.label?.toUpperCase()}</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Available.Per-->0-100%"
                                value={percentages[index]["percentage"] || ''}
                                onChange={(e) => handlePercentageChange(game?.value, e.target.value)}
                                isInvalid={touched.percentages && errors.percentages && errors.percentages[index]}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.percentages && errors.percentages[index]?.percentage}
                              </Form.Control.Feedback>
                            </Form.Group>

                          </Col>
                        ))}
                      </Row>
                    </>


                  )}
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik07">
                      <Form.Label>ELIGIBLE GAME<span className="text-danger">*</span></Form.Label>
                      <MultiSelect
                        options={gameList}
                        value={games.map((val) => gameList?.find((list) => list?.value === val))}
                        onChange={handleGameSelection}
                        labelledBy="Select Game"
                      />
                      {gamesError && <p className="text-danger">At least one game is required</p>}

                    </Form.Group>
                  </Col>
                  {
                    userRole !== 'superAdmin' &&

                    <Col md={6} className="mb-3">
                      <Form.Group controlId="validationFormik08">
                        <Form.Label>{userRole.toUpperCase()} TRANSACTION PASSWORD<span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          type="password"
                          name="verifyPassword"
                          placeholder="Enter Verify Password"
                          value={values.verifyPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.verifyPassword && touched.verifyPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errors.verifyPassword}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  }
                </Row>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  // className="footer-btns"
                >
                  {
                    !isView &&
                    <Button type="submit" className="input_file_button">
                      {isEditMode ? 'UPDATE PARTNER' : 'CREATE PARTNER'}
                    </Button>
                  }

                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default PartnerFormModel;



