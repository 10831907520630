import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { apiAdminConfig } from "../../utils/api";
import { getRole } from "../../utils/cookie";
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';


const TransctionOut = () => {
    const [transctionOutList, setTransctionOutList] = React.useState([]);
    const [userRole, setUserRole] = React.useState(getRole());
    const [loading, setLoading] = React.useState(false);
    const [totalCashIn, setTotalCashIn] = useState(0)
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    const [search, setSearch] = React.useState("");

    const navigate = useNavigate()

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };


    const initialValues = {
        startDate: '',
        endDate: '',
        pickDate: ''
    };

    const getTransctionOutList = async (pickDate = '', toDate = '', fromDate = '') => {
        setLoading(true);
        try {
            const response = await apiAdminConfig.get(
                `points/transactions?search=${search}&type=OUT&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}`
                // `points/transactions?type=OUT&pickDate=`

            );
            if (response?.status === 200) {
                setTransctionOutList(response?.data?.result);

                const totalPoint = response?.data?.result?.reduce((total = 0, item) => {
                    return total + item.points;
                }, 0);

                setTotalCashIn(totalPoint);
            }
        } catch (error) {
            console.log("ListError", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getTransctionOutList(); // Initial load
    }, [search]);

    const columns = [
        {
            title: '#',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {(currentPage - 1) * pageSize + index + 1}
                    </>
                )
            }
        },
        {
            title: 'Before Transaction',
            dataIndex: 'beforePoints',
            key: 'Before Transaction',
        },
        {
            title: 'Transaction Points',
            dataIndex: 'points',
            key: 'Transaction Points',
            render: text => text || '-',
        },
        {
            title: 'After Transaction',
            dataIndex: 'afterPoints',
            key: 'After Transaction',
            render: text => text || '-',
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'Transaction ID',
            render: text => text || '-',
        },
        {
            title: 'Receiver Name',
            dataIndex: 'receiverName',
            key: 'Receiver Name',
            render: (_, record) => record?.receiver?.userName || "N/A",
        },
        {
            title: 'Receiver Type',
            dataIndex: 'receiverType',
            key: 'Receiver Type',
            render: (_, record) => record?.receiver?.role || "N/A",
        },
    ];
    const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async () => {
            const { pickDate, startDate, endDate } = values;
            await getTransctionOutList(pickDate, endDate, startDate);
        },
    });
    return (
        <main>

            <Container className="admin_padding">
                <div style={{ display: "flex", justifyContent: "space-between", margin: "14px 0", alignItems: "center", padding: "0 10px" }} className="table-hader-container">
                    <div className=" breadcumb">
                        <h1 >{userRole?.toUpperCase()} TRANSACTION OUT</h1>
                        <p className="fw-bold "><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / {userRole?.charAt(0)?.toUpperCase() + userRole?.slice(1).toLowerCase()} Transaction Out</p>
                    </div>
                    {/* <div>
                            <h3 className="table-hader-name">{userRole?.charAt(0)?.toUpperCase() + userRole?.slice(1).toLowerCase()} Transaction Out</h3>
                        </div> */}
                </div>
                <div className="admin_form" style={{ width: '98%', marginBottom: '20px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#f33120' }}>DATE TIME FILTER</h6>
                    </div>
                    <Row className="admin_add_form">
                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>START DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="startDate"
                                                value={values.startDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik02">
                                            <Form.Label>END DATE</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="endDate"
                                                value={values.endDate}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6} >
                                        <Form.Group controlId="validationFormik03">
                                            <Form.Label>PICK DATE</Form.Label>
                                            <Form.Select
                                                name="pickDate"
                                                value={values.pickDate}
                                                onChange={handleChange}
                                            >
                                                <option value=''>SELECTED DATE</option>
                                                <option value='TODAY'>TODAY</option>
                                                <option value='YESTERDAY'>YESTERDAY</option>
                                                <option value='THIS_WEEK'>THIS WEEK</option>
                                                <option value='LAST_WEEK'>LAST WEEK</option>
                                                <option value='THIS_MONTH'>THIS MONTH</option>
                                                <option value='LAST_MONTH'>LAST MONTH</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "center" }} className="footer-btns">
                                    <Button type="submit" className="input_file_button">
                                        SUBMIT
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="admin_form" style={{ width: '98%' }}>
                    <div className="detail-heading">
                        <h6 className="text-white">TOTAL TRANSACTION IN</h6>
                    </div>
                    <Row className="admin_add_form">
                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                TOTAL CASH IN
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled
                                                value={totalCashIn}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="admin_padding_table" style={{ padding: 0 ,marginTop:'15px'}}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: 'rgb(56 39 221)', padding: "1rem" }}></h6>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div className="page-size">
                            <span className="fw-bold">Show</span>
                            <select onChange={(e) => setPageSize(e.target.value)}
                                value={pageSize} className="selecte-page-size">
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                            <span className="fw-bold">entries</span>
                        </div>

                        <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                }}
                            />
                        </div>

                    </div>
                    <div className="table-container" style={{ margin: "20px" }}>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        <Table bordered dataSource={transctionOutList} columns={columns} scroll={{ x: 525 }}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                total: transctionOutList.length,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                },
                                showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                            }}

                            onChange={handleTableChange} />
                    </div>
                </div>
            </Container>
        </main >
    );
};

export default TransctionOut;

