import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import "./style.scss";
import { useFormik } from "formik";
import { apiAdminConfig } from "../../utils/api";

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const initialValues = {
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    game: ''
  };

  const [gameList, setGameList] = useState([]);
  const [dashboardData, setDashboardData] = useState(null)

  const [playPoint, setPlayPoint] = useState([])
  const [wonPoint, setWonPoint] = useState([])
  const [endPoint, setEndPoint] = useState([])
  const [playDate, setPlayDate] = useState([])

  const getDateWisePoint = async (startDate = '', endDate = '', gameId = '') => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    startDate = startDate || formattedDate;
    endDate = endDate || formattedDate;
    // let url
    // if (gameId == 'Select All') {
    //   url=  `users/dashboard?startDate=${startDate}&endDate=${endDate}&gameId=`
    // } else if (!!gameId) {
    //   url=  `users/dashboard?startDate=${startDate}&endDate=${endDate}&gameId=${gameId}`
    // } else {
    let  url= !!gameId ? `users/dashboard?startDate=${startDate}&endDate=${endDate}&gameId=${gameId}`: `users/dashboard?startDate=${startDate}&endDate=${endDate}`
    // }
    // let url = !!gameId ?  : `users/dashboard?startDate=${startDate}&endDate=${endDate}`
    await apiAdminConfig.get(url).then((response) => {
      // await apiAdminConfig.get(`users/dashboard`).then((response) => {
      console.log("response", response.data.gamePrediction)
      if (response?.status == 200) {
        let playPointData = []
        let endPointData = []
        let wonPointData = []
        let date = []

        response?.data?.gamePrediction?.forEach((data) => {
          playPointData.push(data?.playPoints)
          endPointData.push(data?.endPoint)
          wonPointData.push(data?.winPoints)
          date.push(data?.date)
        })
        setPlayPoint(playPointData)
        setWonPoint(wonPointData)
        setEndPoint(endPointData)
        setPlayDate(date)
      }
    }).catch((error) => {
      console.log("error", error);
    })
  }

  useEffect(() => {
    getDateWisePoint()
  }, [])

  const data = {
    // labels: ['Oct 21', 'Oct 23', 'Oct 25', 'Oct 27', 'Oct 29', 'Oct 31', 'Nov 2'],
    labels: playDate,
    datasets: [
      {
        label: 'Play Point',
        // data: [1000000, 300000, 700000, 400000, 200000, 500000, 600000],
        data: playPoint,
        borderColor: '#00BFFF',
        backgroundColor: '#00BFFF',
        pointStyle: 'circle',
        pointRadius: 6,
        fill: false,
        yAxisID: 'y1',
      },
      {
        label: 'Won Point',
        // data: [900000, 200000, 500000, 400000, 200000, 500000, 600000],
        data: wonPoint,
        borderColor: '#800080',
        backgroundColor: '#800080',
        pointStyle: 'triangle',
        pointRadius: 6,
        fill: false,
        yAxisID: 'y2',
      },
      {
        label: 'End Point',
        // data: [600000, 100000, 400000, 400000, 200000, 500000, 600000],
        data: endPoint,
        borderColor: '#8A2BE2',
        backgroundColor: '#8A2BE2',
        pointStyle: 'square',
        pointRadius: 6,
        fill: false,
        yAxisID: 'y3',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Game Percentage',
      },
    },
    scales: {
      y1: {
        type: 'linear',
        position: 'left',
        beginAtZero: true,
        title: {
          display: true,
          color: '#800080',
        },
        ticks: {
          color: '#800080',
        },
        grid: {
          drawOnChartArea: false,
          color: '#800080',
        },
        border: {
          color: '#800080', // Color for the Y-axis line
        },
      },
      y2: {
        type: 'linear',
        position: 'left',
        beginAtZero: true,
        title: {
          display: true,
          color: '#00BFFF',
        },
        ticks: {
          color: '#00BFFF',
        },
        grid: {
          drawOnChartArea: false,
          color: '#00BFFF',
        },
        border: {
          color : '#00BFFF'
        },
      },
      y3: {
        type: 'linear',
        position: 'right',
        beginAtZero: true,
        title: {
          display: true,
          color: '#8A2BE2',
        },
        ticks: {
          color: '#8A2BE2',
        },
        grid: {
          drawOnChartArea: false,
          color: '#8A2BE2',
        },
        border: {
          color: '#8A2BE2', // Color for the Y-axis line
        },
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
    },
  };


  const getGameList = async () => {
    await apiAdminConfig.get("games/get").then((response) => {
      if (response?.data?.results?.length > 0) {
        setGameList(
          response.data.results.map((list) => ({
            label: list?.name ?? '',
            value: list?.id
          })) ?? []
        );
      }
    }).catch((error) => {
      console.log("gameListError", error);
    });
  }


  const getDashboardData = async () => {
    await apiAdminConfig.get(`users/dashboard`).then((response) => {
      if (response?.status == 200) {
        setDashboardData(response?.data)
      }
    }).catch((error) => {
      console.log("error", error);
    })
  }

  useEffect(() => {
    getDashboardData()
    getGameList()
  }, []);

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async () => {
      const { startDate,
        endDate,
        game } = values
      getDateWisePoint(startDate, endDate, game)
      console.log("values", values);
    },
  });

  const [windowHeight, setWindowHeight] = useState(window.innerWidth <= 767);

  // useEffect(() => {
  //   const handleResize = () => {
  //     console.log("window.innerHeight",window.innerHeight)
  //     setWindowHeight(window.innerHeight);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      // setIsWindowShow(window.innerWidth >= 767)
      setWindowHeight(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log("windowHeight", windowHeight)
  const chartHeight = windowHeight ? 450 : 150;

  console.log("chartHeight", chartHeight)
  return (
    <main id="dashboard" style={{ backgroundColor: '#f0f0f0', paddingTop: '10px' }}>
      <div className="container dashboard-breadcumb">
        <h1 className="mt-4">DASHBOARD</h1>
        <p className="fw-bold"><span style={{ color: 'blue' }}>Home </span> / Dashboard</p>
      </div>
      <div className="container">
        <div className='d-flex mt-4' style={{ padding: '10px' }}>
          {/* <div className='dashboard-card text-white bg-primary'>
            <div className="card-body">
              <h5 className="card-title">AVAILABLE POINTS</h5>
              <p className="card-text">{dashboardData?.availablePoints ?? 0}</p>
            </div>
          </div> */}
          <div className='dashboard-card text-white first-card'>
            <div className="card-body">
              <h5 className="card-title">AVAILABLE POINTS</h5>
              <p className="card-text">{dashboardData?.availablePoints ?? 0}</p>
            </div>
          </div>
          <div className='dashboard-card text-white second-card'>
            {/* <div className="card-header">User</div> */}
            <div className="card-body">
              <h5 className="card-title">TOTAL ONLINE USERS</h5>
              <p className="card-text">{dashboardData?.onlineUsers}</p>
            </div>
          </div>
          <div className='dashboard-card text-white third-card'>
            <div className="card-body third-card{" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
              <div>
                <h5 className="card-title">PC USERS</h5>
                <p className="card-text">{dashboardData?.pcUsers}</p>
              </div>
              <div>
                <h5 className="card-title">MOBILE USERS</h5>
                <p className="card-text">{dashboardData?.mobileUsers}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Container className="admin_padding">
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">Datepicker</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        START DATE
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik02">
                      <Form.Label>
                        END DATE
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="validationFormik03">
                      <Form.Label>
                        SELECT GAME <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="game"
                        value={values.game}
                        onChange={handleChange}
                      >
                        {/* <option value=''>Select Game</option> */}
                        <option value=''>Select All</option>

                        {gameList.map((game, index) =>{
                        return  <>
                          <option key={index} value={game.value}>{game.label}</option>
                          </> 
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ display: "flex", justifyContent: "center" }} className="footer-btns">
                  <Button type="submit" className="input_file_button">
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
      {/* <div className="container" style={{backgroundColor:'white'}}> */}
      {/* <div style={{  height: '400px' ,width:'92%'}}>  */}
      <Line data={data} height={chartHeight} options={options} style={{ backgroundColor: 'white', width: '92%', margin: 'auto', paddingRight: '10px', paddingLeft: '10px' }} />
      {/* </div> */}




    </main >
  );
};

export default Dashboard;

